export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: string; output: string };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string };
  /** Represents untyped JSON */
  JSON: { input: any; output: any };
};

export type AccountList = {
  __typename?: 'AccountList';
  activeMpdFinishAt?: Maybe<Scalars['ISO8601Date']['output']>;
  activeMpdMonthlyGoal?: Maybe<Scalars['Float']['output']>;
  activeMpdStartAt?: Maybe<Scalars['ISO8601Date']['output']>;
  appeals?: Maybe<Array<Appeal>>;
  balance: Scalars['Float']['output'];
  churches: Array<Scalars['String']['output']>;
  coaches: UserScopedToAccountListConnection;
  contactFilterGroups: Array<FilterGroup>;
  contactTagList: Array<Scalars['String']['output']>;
  contacts: ContactConnection;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: Scalars['String']['output'];
  designationAccounts: Array<DesignationAccount>;
  id: Scalars['ID']['output'];
  monthlyGoal?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partnerGivingAnalysisFilterGroups: Array<FilterGroup>;
  primaryAppeal?: Maybe<Appeal>;
  receivedPledges: Scalars['Float']['output'];
  salaryOrganizationId?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<AccountListSettings>;
  taskFilterGroups: Array<FilterGroup>;
  taskTagList: Array<Scalars['String']['output']>;
  totalPledges: Scalars['Float']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  users: UserScopedToAccountListConnection;
  weeksOnMpd: Scalars['Int']['output'];
};

export type AccountListChurchesArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type AccountListCoachesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AccountListContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ContactFilterSetInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AccountListUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AccountListAnalytics = {
  __typename?: 'AccountListAnalytics';
  appointments: AppointmentsAccountListAnalytics;
  contacts: ContactsAccountListAnalytics;
  contactsByStatus: ContactsByStatus;
  correspondence: CorrespondenceAccountListAnalytics;
  electronic: ElectronicAccountListAnalytics;
  email: EmailsAccountListAnalytics;
  endDate: Scalars['ISO8601DateTime']['output'];
  facebook: FacebookAccountListAnalytics;
  phone: PhoneAccountListAnalytics;
  startDate: Scalars['ISO8601DateTime']['output'];
  textMessage: TextMessageAccountListAnalytics;
};

/** Autogenerated input type of AccountListCoachDeleteMutation */
export type AccountListCoachDeleteMutationInput = {
  /** account list id */
  accountListId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the coach to remove */
  coachId?: InputMaybe<Scalars['ID']['input']>;
  /** account list id (DEPRECATED: please use account_list_id AND coach_id instead) */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of AccountListCoachDeleteMutation */
export type AccountListCoachDeleteMutationPayload = {
  __typename?: 'AccountListCoachDeleteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type AccountListCoaches = {
  __typename?: 'AccountListCoaches';
  accountListsId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedInDbAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  userCoachesId?: Maybe<Scalars['ID']['output']>;
};

/** The connection type for AccountList. */
export type AccountListConnection = {
  __typename?: 'AccountListConnection';
  /** A list of edges. */
  edges: Array<AccountListEdge>;
  /** A list of nodes. */
  nodes: Array<AccountList>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

export type AccountListDesignationAccounts = {
  __typename?: 'AccountListDesignationAccounts';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  organization?: Maybe<AccountListOrganization>;
};

export type AccountListDonorAccount = {
  __typename?: 'AccountListDonorAccount';
  accountNumber: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

/** An edge in a connection. */
export type AccountListEdge = {
  __typename?: 'AccountListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AccountList>;
};

export type AccountListEmailAddresses = {
  __typename?: 'AccountListEmailAddresses';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  primary?: Maybe<Scalars['Boolean']['output']>;
};

export type AccountListInvite = {
  __typename?: 'AccountListInvite';
  accountListId: Scalars['ID']['output'];
  cancelledByUser?: Maybe<UserScopedToAccountList>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  inviteUserAs?: Maybe<InviteTypeEnum>;
  invitedByUser: UserScopedToAccountList;
  recipientEmail: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for AccountListInvite. */
export type AccountListInviteConnection = {
  __typename?: 'AccountListInviteConnection';
  /** A list of edges. */
  edges: Array<AccountListInviteEdge>;
  /** A list of nodes. */
  nodes: Array<AccountListInvite>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

export type AccountListInviteCreateInput = {
  accountListId: Scalars['ID']['input'];
  inviteUserAs: InviteTypeEnum;
  recipientEmail: Scalars['String']['input'];
};

/** An edge in a connection. */
export type AccountListInviteEdge = {
  __typename?: 'AccountListInviteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AccountListInvite>;
};

export type AccountListInvitedByUser = {
  __typename?: 'AccountListInvitedByUser';
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type AccountListInvites = {
  __typename?: 'AccountListInvites';
  id?: Maybe<Scalars['ID']['output']>;
  inviteUserAs?: Maybe<Scalars['String']['output']>;
  invitedByUser?: Maybe<AccountListInvitedByUser>;
  recipientEmail?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AccountListMergeMutation */
export type AccountListMergeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  losingAccountListId: Scalars['ID']['input'];
  winningAccountListId: Scalars['ID']['input'];
};

/** Autogenerated return type of AccountListMergeMutation */
export type AccountListMergeMutationPayload = {
  __typename?: 'AccountListMergeMutationPayload';
  accountList: AccountList;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type AccountListOrganization = {
  __typename?: 'AccountListOrganization';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AccountListPledgeCreateMutation */
export type AccountListPledgeCreateMutationInput = {
  /** AccountList ID that the appeal will be found from */
  accountListId: Scalars['ID']['input'];
  /** Attributes of created pledge */
  attributes: PledgeCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AccountListPledgeCreateMutation */
export type AccountListPledgeCreateMutationPayload = {
  __typename?: 'AccountListPledgeCreateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  pledge: Pledge;
};

/** Autogenerated input type of AccountListPledgeDeleteMutation */
export type AccountListPledgeDeleteMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the pledge to remove */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of AccountListPledgeDeleteMutation */
export type AccountListPledgeDeleteMutationPayload = {
  __typename?: 'AccountListPledgeDeleteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

/** Autogenerated input type of AccountListPledgeUpdateMutation */
export type AccountListPledgeUpdateMutationInput = {
  /** Attributes of updated pledge */
  attributes: PledgeUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Pledge ID that will be updated */
  pledgeId: Scalars['ID']['input'];
};

/** Autogenerated return type of AccountListPledgeUpdateMutation */
export type AccountListPledgeUpdateMutationPayload = {
  __typename?: 'AccountListPledgeUpdateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  pledge: Pledge;
};

/** Autogenerated input type of AccountListResetMutation */
export type AccountListResetMutationInput = {
  accountListId?: InputMaybe<Scalars['String']['input']>;
  accountListName?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reason: Scalars['String']['input'];
  resettedUserEmail?: InputMaybe<Scalars['String']['input']>;
  resettedUserId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AccountListResetMutation */
export type AccountListResetMutationPayload = {
  __typename?: 'AccountListResetMutationPayload';
  accountList: AccountList;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type AccountListSettings = {
  __typename?: 'AccountListSettings';
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  homeCountry?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  monthlyGoal?: Maybe<Scalars['Float']['output']>;
  tester?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type AccountListSettingsInput = {
  currency?: InputMaybe<Scalars['String']['input']>;
  homeCountry?: InputMaybe<Scalars['String']['input']>;
  monthlyGoal?: InputMaybe<Scalars['Float']['input']>;
  tester?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountListUpdateInput = {
  activeMpdFinishAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  activeMpdMonthlyGoal?: InputMaybe<Scalars['Float']['input']>;
  activeMpdStartAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  salaryOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  settings?: InputMaybe<AccountListSettingsInput>;
};

/** Autogenerated input type of AccountListUpdateMutation */
export type AccountListUpdateMutationInput = {
  attributes: AccountListUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of AccountListUpdateMutation */
export type AccountListUpdateMutationPayload = {
  __typename?: 'AccountListUpdateMutationPayload';
  accountList: AccountList;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type AccountListUser = {
  __typename?: 'AccountListUser';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: UserScopedToAccountList;
};

/** The connection type for AccountListUser. */
export type AccountListUserConnection = {
  __typename?: 'AccountListUserConnection';
  /** A list of edges. */
  edges: Array<AccountListUserEdge>;
  /** A list of nodes. */
  nodes: Array<AccountListUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** Autogenerated input type of AccountListUserDeleteMutation */
export type AccountListUserDeleteMutationInput = {
  /** account list id */
  accountListId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the user to remove */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of AccountListUserDeleteMutation */
export type AccountListUserDeleteMutationPayload = {
  __typename?: 'AccountListUserDeleteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

/** An edge in a connection. */
export type AccountListUserEdge = {
  __typename?: 'AccountListUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AccountListUser>;
};

export type AccountListUsers = {
  __typename?: 'AccountListUsers';
  allowDeletion?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastSyncedAt?: Maybe<Scalars['String']['output']>;
  organizationCount?: Maybe<Scalars['Int']['output']>;
  userEmailAddresses?: Maybe<Array<Maybe<AccountListEmailAddresses>>>;
  userFirstName?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  userLastName?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AcknowledgeAllUserNotificationsMutation */
export type AcknowledgeAllUserNotificationsMutationInput = {
  accountListId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AcknowledgeAllUserNotificationsMutation */
export type AcknowledgeAllUserNotificationsMutationPayload = {
  __typename?: 'AcknowledgeAllUserNotificationsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  notificationIds: Array<Scalars['ID']['output']>;
};

/** Autogenerated input type of AcknowledgeAnnouncementMutation */
export type AcknowledgeAnnouncementMutationInput = {
  /** action being triggered by user (must belong to announcement being acknowledged) */
  actionId?: InputMaybe<Scalars['ID']['input']>;
  /** announcement to acknowledge (will no longer be visible under announcements query) */
  announcementId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AcknowledgeAnnouncementMutation */
export type AcknowledgeAnnouncementMutationPayload = {
  __typename?: 'AcknowledgeAnnouncementMutationPayload';
  announcement: Announcement;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AcknowledgeUserNotificationMutation */
export type AcknowledgeUserNotificationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** userNotification to acknowledge (will mark userNotification as read) */
  notificationId: Scalars['ID']['input'];
};

/** Autogenerated return type of AcknowledgeUserNotificationMutation */
export type AcknowledgeUserNotificationMutationPayload = {
  __typename?: 'AcknowledgeUserNotificationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  notification: UserNotification;
};

export type Action = {
  __typename?: 'Action';
  action?: Maybe<ActionEnum>;
  args?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  style?: Maybe<ActionStyleEnum>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum ActionEnum {
  /** this action should create an appeal. */
  AppealCreate = 'appeal_create',
  /** this action should navigate to a specific location (use args to determine destination) */
  Go = 'go',
  /** this action should dispatch an event to adobe analytics (use args to determine event to dispatch) */
  Track = 'track',
}

export enum ActionStyleEnum {
  /** announcement should be colored as a danger button */
  Danger = 'danger',
  /** action should not have coloring */
  Default = 'default',
  /** announcement should be colored as a font-awesome icon (use label as icon type) */
  Icon = 'icon',
  /** action should be colored as an infomational button */
  Info = 'info',
  /** announcement should be colored as a link */
  Link = 'link',
  /** action should be colored as a primary button */
  Primary = 'primary',
  /** announcement should be colored as a reverse button */
  Reverse = 'reverse',
  /** action should be colored as a secondary button */
  Secondary = 'secondary',
  /** announcement should be colored as a success button */
  Success = 'success',
  /** action should be colored as a warning button */
  Warning = 'warning',
}

export type ActivitiesConstant = {
  __typename?: 'ActivitiesConstant';
  id: ActivityTypeEnum;
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ActivityResults = {
  __typename?: 'ActivityResults';
  periods: Array<ActivityResultsPeriod>;
};

export type ActivityResultsPeriod = {
  __typename?: 'ActivityResultsPeriod';
  appointmentInPerson: Scalars['Int']['output'];
  appointmentPhoneCall: Scalars['Int']['output'];
  appointmentTotal: Scalars['Int']['output'];
  appointmentVideoCall: Scalars['Int']['output'];
  callsWithAppointmentNext: Scalars['Int']['output'];
  completedCall: Scalars['Int']['output'];
  completedPreCallLetter: Scalars['Int']['output'];
  completedReminderLetter: Scalars['Int']['output'];
  completedSupportLetter: Scalars['Int']['output'];
  completedThank: Scalars['Int']['output'];
  contactsAdded: Scalars['Int']['output'];
  contactsReferred: Scalars['Int']['output'];
  contactsTotal: Scalars['Int']['output'];
  dials: Scalars['Int']['output'];
  electronicMessageSent: Scalars['Int']['output'];
  electronicMessageWithAppointmentNext: Scalars['Int']['output'];
  endDate: Scalars['ISO8601Date']['output'];
  followUpEmail: Scalars['Int']['output'];
  followUpInPerson: Scalars['Int']['output'];
  followUpPhoneCall: Scalars['Int']['output'];
  followUpSocialMedia: Scalars['Int']['output'];
  followUpTextMessage: Scalars['Int']['output'];
  followUpTotal: Scalars['Int']['output'];
  initiationEmail: Scalars['Int']['output'];
  initiationInPerson: Scalars['Int']['output'];
  initiationLetter: Scalars['Int']['output'];
  initiationPhoneCall: Scalars['Int']['output'];
  initiationSocialMedia: Scalars['Int']['output'];
  initiationSpecialGiftAppeal: Scalars['Int']['output'];
  initiationTextMessage: Scalars['Int']['output'];
  initiationTotal: Scalars['Int']['output'];
  partnerCareDigitalNewsletter: Scalars['Int']['output'];
  partnerCarePhysicalNewsletter: Scalars['Int']['output'];
  partnerCarePrayerRequest: Scalars['Int']['output'];
  partnerCareThank: Scalars['Int']['output'];
  partnerCareToDo: Scalars['Int']['output'];
  partnerCareTotal: Scalars['Int']['output'];
  partnerCareUpdateInformation: Scalars['Int']['output'];
  startDate: Scalars['ISO8601Date']['output'];
};

export enum ActivityTypeEnum {
  AppointmentInPerson = 'APPOINTMENT_IN_PERSON',
  AppointmentPhoneCall = 'APPOINTMENT_PHONE_CALL',
  AppointmentVideoCall = 'APPOINTMENT_VIDEO_CALL',
  FollowUpEmail = 'FOLLOW_UP_EMAIL',
  FollowUpInPerson = 'FOLLOW_UP_IN_PERSON',
  FollowUpPhoneCall = 'FOLLOW_UP_PHONE_CALL',
  FollowUpSocialMedia = 'FOLLOW_UP_SOCIAL_MEDIA',
  FollowUpTextMessage = 'FOLLOW_UP_TEXT_MESSAGE',
  InitiationEmail = 'INITIATION_EMAIL',
  InitiationInPerson = 'INITIATION_IN_PERSON',
  InitiationLetter = 'INITIATION_LETTER',
  InitiationPhoneCall = 'INITIATION_PHONE_CALL',
  InitiationSocialMedia = 'INITIATION_SOCIAL_MEDIA',
  InitiationSpecialGiftAppeal = 'INITIATION_SPECIAL_GIFT_APPEAL',
  InitiationTextMessage = 'INITIATION_TEXT_MESSAGE',
  /** special type when filtered by will return any task with no activityType */
  None = 'NONE',
  PartnerCareDigitalNewsletter = 'PARTNER_CARE_DIGITAL_NEWSLETTER',
  PartnerCareEmail = 'PARTNER_CARE_EMAIL',
  PartnerCareInPerson = 'PARTNER_CARE_IN_PERSON',
  PartnerCarePhoneCall = 'PARTNER_CARE_PHONE_CALL',
  PartnerCarePhysicalNewsletter = 'PARTNER_CARE_PHYSICAL_NEWSLETTER',
  PartnerCarePrayerRequest = 'PARTNER_CARE_PRAYER_REQUEST',
  PartnerCareSocialMedia = 'PARTNER_CARE_SOCIAL_MEDIA',
  PartnerCareTextMessage = 'PARTNER_CARE_TEXT_MESSAGE',
  PartnerCareThank = 'PARTNER_CARE_THANK',
  PartnerCareToDo = 'PARTNER_CARE_TO_DO',
  PartnerCareUpdateInformation = 'PARTNER_CARE_UPDATE_INFORMATION',
}

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  geo?: Maybe<Scalars['String']['output']>;
  historic: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  metroArea?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  primaryMailingAddress: Scalars['Boolean']['output'];
  region?: Maybe<Scalars['String']['output']>;
  source: Scalars['String']['output'];
  sourceDonorAccount?: Maybe<DonorAccount>;
  startDate?: Maybe<Scalars['ISO8601Date']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  validValues: Scalars['Boolean']['output'];
};

/** The connection type for Address. */
export type AddressConnection = {
  __typename?: 'AddressConnection';
  /** A list of edges. */
  edges: Array<AddressEdge>;
  /** A list of nodes. */
  nodes: Array<Address>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

export type AddressCreateInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  historic?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  metroArea?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street: Scalars['String']['input'];
};

/** Autogenerated input type of AddressCreateMutation */
export type AddressCreateMutationInput = {
  /** AccountList ID that address is associated with */
  accountListId: Scalars['ID']['input'];
  /** attributes to create address */
  attributes: AddressCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AddressCreateMutation */
export type AddressCreateMutationPayload = {
  __typename?: 'AddressCreateMutationPayload';
  address: Address;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AddressDeleteMutation */
export type AddressDeleteMutationInput = {
  accountListId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of AddressDeleteMutation */
export type AddressDeleteMutationPayload = {
  __typename?: 'AddressDeleteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

/** An edge in a connection. */
export type AddressEdge = {
  __typename?: 'AddressEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Address>;
};

export type AddressUpdateInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  historic?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  metroArea?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  primaryMailingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  validValues?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of AddressUpdateMutation */
export type AddressUpdateMutationInput = {
  accountListId: Scalars['ID']['input'];
  /** attributes to update address */
  attributes: AddressUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AddressUpdateMutation */
export type AddressUpdateMutationPayload = {
  __typename?: 'AddressUpdateMutationPayload';
  address: Address;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type AddressWithPrimary = {
  __typename?: 'AddressWithPrimary';
  id: Scalars['ID']['output'];
  primaryMailingAddress: Scalars['Boolean']['output'];
};

export type AdminDeleteOrganizationCoachInput = {
  accountListId: Scalars['ID']['input'];
  coachId: Scalars['ID']['input'];
};

export type AdminDeleteOrganizationInviteInput = {
  accountListId: Scalars['ID']['input'];
  inviteId: Scalars['ID']['input'];
};

export type AdminDeleteOrganizationUserInput = {
  accountListId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type AdminList = {
  __typename?: 'AdminList';
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
};

export type AllOrganizations = {
  __typename?: 'AllOrganizations';
  organizations: Array<Maybe<Organizations>>;
  pagination: AllOrganizationsPagination;
};

export type AllOrganizationsPagination = {
  __typename?: 'AllOrganizationsPagination';
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type Announcement = {
  __typename?: 'Announcement';
  actions: Array<Action>;
  body?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  displayMethod: DisplayMethodEnum;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  style?: Maybe<StyleEnum>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of ApiOauthSignInMutation */
export type ApiOauthSignInMutationInput = {
  accessToken: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ApiOauthSignInMutation */
export type ApiOauthSignInMutationPayload = {
  __typename?: 'ApiOauthSignInMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type Appeal = {
  __typename?: 'Appeal';
  active: Scalars['Boolean']['output'];
  amount?: Maybe<Scalars['Float']['output']>;
  amountCurrency: Scalars['String']['output'];
  contactIds?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currencies?: Maybe<Array<Scalars['String']['output']>>;
  description: Scalars['String']['output'];
  endDate?: Maybe<Scalars['ISO8601Date']['output']>;
  excludedAppealContacts?: Maybe<Array<ExcludedAppealContact>>;
  excludedContacts?: Maybe<Array<Contact>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pledges: PledgeConnection;
  pledgesAmountNotReceivedNotProcessed: Scalars['Float']['output'];
  pledgesAmountProcessed: Scalars['Float']['output'];
  pledgesAmountReceivedNotProcessed: Scalars['Float']['output'];
  pledgesAmountTotal: Scalars['Float']['output'];
  primary: Scalars['Boolean']['output'];
  totalCurrency?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type AppealPledgesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Appeal. */
export type AppealConnection = {
  __typename?: 'AppealConnection';
  /** A list of edges. */
  edges: Array<AppealEdge>;
  /** A list of nodes. */
  nodes: Array<Appeal>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

export type AppealContact = {
  __typename?: 'AppealContact';
  contact: Contact;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  pledgedToAppeal: Scalars['Boolean']['output'];
  pledges?: Maybe<Array<Pledge>>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for AppealContact. */
export type AppealContactConnection = {
  __typename?: 'AppealContactConnection';
  /** A list of edges. */
  edges: Array<AppealContactEdge>;
  /** A list of nodes. */
  nodes: Array<AppealContact>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** Autogenerated input type of AppealContactDeleteMutation */
export type AppealContactDeleteMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of appeal contact to delete */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of AppealContactDeleteMutation */
export type AppealContactDeleteMutationPayload = {
  __typename?: 'AppealContactDeleteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

/** An edge in a connection. */
export type AppealContactEdge = {
  __typename?: 'AppealContactEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AppealContact>;
};

export type AppealCreateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** A json hash of exclusion filters (see app/services/contact/filter/ for all options) to determine which contacts to exclude */
  exclusionFilter?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** A json hash of inclusion filters (see app/services/contact/filter/ for all options) to determine which contacts to include */
  inclusionFilter?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  primary?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of AppealCreateMutation */
export type AppealCreateMutationInput = {
  /** AccountList ID that the contact is associated with */
  accountListId: Scalars['ID']['input'];
  /** attributes to create */
  attributes: AppealCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AppealCreateMutation */
export type AppealCreateMutationPayload = {
  __typename?: 'AppealCreateMutationPayload';
  appeal: Appeal;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AppealDeleteMutation */
export type AppealDeleteMutationInput = {
  /** AccountList ID that the appeal is associated with */
  accountListId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of appeal to delete */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of AppealDeleteMutation */
export type AppealDeleteMutationPayload = {
  __typename?: 'AppealDeleteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

/** An edge in a connection. */
export type AppealEdge = {
  __typename?: 'AppealEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Appeal>;
};

export type AppealUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of AppealUpdateMutation */
export type AppealUpdateMutationInput = {
  /** AccountList ID that the appeal is associated with */
  accountListId: Scalars['ID']['input'];
  /** attributes to update */
  attributes: AppealUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AppealUpdateMutation */
export type AppealUpdateMutationPayload = {
  __typename?: 'AppealUpdateMutationPayload';
  appeal: Appeal;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type AppointmentsAccountListAnalytics = {
  __typename?: 'AppointmentsAccountListAnalytics';
  completed: Scalars['Int']['output'];
};

export type AssignContactsToAppealInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  contactIds: Array<Scalars['ID']['input']>;
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  forceListDeletion?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID blank if you want to create the new Appeal */
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of AssignContactsToAppealMutation */
export type AssignContactsToAppealMutationInput = {
  /** AccountList ID that the appeal is associated with */
  accountListId: Scalars['ID']['input'];
  /** attributes to assign */
  attributes: AssignContactsToAppealInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AssignContactsToAppealMutation */
export type AssignContactsToAppealMutationPayload = {
  __typename?: 'AssignContactsToAppealMutationPayload';
  appeal: Appeal;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CanUserExportData = {
  __typename?: 'CanUserExportData';
  allowed: Scalars['Boolean']['output'];
  exportedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Autogenerated input type of CancelAccountListInvite */
export type CancelAccountListInviteInput = {
  /** if not an admin or Organization::Admin must pass in current account_list_id */
  accountListId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CancelAccountListInvite */
export type CancelAccountListInvitePayload = {
  __typename?: 'CancelAccountListInvitePayload';
  accountListInvite: AccountListInvite;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type Category = {
  __typename?: 'Category';
  code?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  entryType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  remoteId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Category. */
export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  /** A list of edges. */
  edges: Array<CategoryEdge>;
  /** A list of nodes. */
  nodes: Array<Category>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Category>;
};

export type CheckboxFilter = Filter & {
  __typename?: 'CheckboxFilter';
  filterKey: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CoachingAccountList = {
  __typename?: 'CoachingAccountList';
  activeMpdFinishAt?: Maybe<Scalars['ISO8601Date']['output']>;
  activeMpdMonthlyGoal?: Maybe<Scalars['Float']['output']>;
  activeMpdStartAt?: Maybe<Scalars['ISO8601Date']['output']>;
  balance: Scalars['Float']['output'];
  coaches: UserScopedToAccountListConnection;
  contactTagList: Array<Scalars['String']['output']>;
  contacts: CoachingContactConnection;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: Scalars['String']['output'];
  designationAccounts: Array<DesignationAccount>;
  id: Scalars['ID']['output'];
  monthlyGoal?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  primaryAppeal?: Maybe<CoachingAppeal>;
  receivedPledges: Scalars['Float']['output'];
  taskTagList: Array<Scalars['String']['output']>;
  totalPledges: Scalars['Float']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  users: UserScopedToAccountListConnection;
  weeksOnMpd: Scalars['Int']['output'];
};

export type CoachingAccountListCoachesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CoachingAccountListContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ContactFilterSetInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CoachingAccountListUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for CoachingAccountList. */
export type CoachingAccountListConnection = {
  __typename?: 'CoachingAccountListConnection';
  /** A list of edges. */
  edges: Array<CoachingAccountListEdge>;
  /** A list of nodes. */
  nodes: Array<CoachingAccountList>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CoachingAccountListEdge = {
  __typename?: 'CoachingAccountListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CoachingAccountList>;
};

export type CoachingAnswer = {
  __typename?: 'CoachingAnswer';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  question: CoachingQuestion;
  response?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type CoachingAnswerSet = {
  __typename?: 'CoachingAnswerSet';
  answers: Array<CoachingAnswer>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  questions: Array<CoachingQuestion>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type CoachingAppeal = {
  __typename?: 'CoachingAppeal';
  active: Scalars['Boolean']['output'];
  amount?: Maybe<Scalars['Float']['output']>;
  amountCurrency: Scalars['String']['output'];
  contactIds?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currencies?: Maybe<Array<Scalars['String']['output']>>;
  description: Scalars['String']['output'];
  endDate?: Maybe<Scalars['ISO8601Date']['output']>;
  excludedAppealContacts?: Maybe<Array<ExcludedAppealContact>>;
  excludedContacts?: Maybe<Array<Contact>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pledges: CoachingPledgeConnection;
  pledgesAmountNotReceivedNotProcessed: Scalars['Float']['output'];
  pledgesAmountProcessed: Scalars['Float']['output'];
  pledgesAmountReceivedNotProcessed: Scalars['Float']['output'];
  pledgesAmountTotal: Scalars['Float']['output'];
  primary: Scalars['Boolean']['output'];
  totalCurrency?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type CoachingAppealPledgesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CoachingContact = {
  __typename?: 'CoachingContact';
  contactPhase?: Maybe<PhaseEnum>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  lateAt?: Maybe<Scalars['ISO8601Date']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  pledgeAmount?: Maybe<Scalars['Float']['output']>;
  pledgeCurrency?: Maybe<Scalars['String']['output']>;
  /** Attempted conversion of pledgeCurrency to symbol with fallback to pledgeCurrency value */
  pledgeCurrencySymbol?: Maybe<Scalars['String']['output']>;
  pledgeFrequency?: Maybe<PledgeFrequencyEnum>;
  pledgeReceived: Scalars['Boolean']['output'];
  pledgeStartDate?: Maybe<Scalars['ISO8601Date']['output']>;
  pledges: Array<Pledge>;
  status?: Maybe<StatusEnum>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for CoachingContact. */
export type CoachingContactConnection = {
  __typename?: 'CoachingContactConnection';
  /** A list of edges. */
  edges: Array<CoachingContactEdge>;
  /** A list of nodes. */
  nodes: Array<CoachingContact>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CoachingContactEdge = {
  __typename?: 'CoachingContactEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CoachingContact>;
};

export type CoachingPledge = {
  __typename?: 'CoachingPledge';
  amount: Scalars['Float']['output'];
  amountCurrency?: Maybe<Scalars['String']['output']>;
  appeal: CoachingAppeal;
  contact: CoachingContact;
  createdAt: Scalars['ISO8601DateTime']['output'];
  expectedDate: Scalars['ISO8601Date']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for CoachingPledge. */
export type CoachingPledgeConnection = {
  __typename?: 'CoachingPledgeConnection';
  /** A list of edges. */
  edges: Array<CoachingPledgeEdge>;
  /** A list of nodes. */
  nodes: Array<CoachingPledge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CoachingPledgeEdge = {
  __typename?: 'CoachingPledgeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CoachingPledge>;
};

export type CoachingQuestion = {
  __typename?: 'CoachingQuestion';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  prompt: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  responseOptions?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Comment = {
  __typename?: 'Comment';
  body: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  me: Scalars['Boolean']['output'];
  person?: Maybe<UserScopedToAccountList>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Comment. */
export type CommentConnection = {
  __typename?: 'CommentConnection';
  /** A list of edges. */
  edges: Array<CommentEdge>;
  /** A list of nodes. */
  nodes: Array<Comment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

export type CommentDeleteMutationInput = {
  commentId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
};

export type CommentDeleteMutationPayload = {
  __typename?: 'CommentDeleteMutationPayload';
  id: Scalars['ID']['output'];
};

/** An edge in a connection. */
export type CommentEdge = {
  __typename?: 'CommentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Comment>;
};

export type CommentUpdateMutationInput = {
  body: Scalars['String']['input'];
  commentId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
};

export type CommentUpdateMutationPayload = {
  __typename?: 'CommentUpdateMutationPayload';
  id: Scalars['ID']['output'];
};

export type Constant = {
  __typename?: 'Constant';
  activities?: Maybe<Array<ActivitiesConstant>>;
  csvRequiredHeaders?: Maybe<Array<IdValue>>;
  csvSupportedHeaders?: Maybe<Array<IdValue>>;
  importMaxFileSizeInBytes?: Maybe<Scalars['Int']['output']>;
  languages?: Maybe<Array<IdValue>>;
  likelyToGive?: Maybe<Array<IdValue>>;
  likelyToGiveOptions?: Maybe<Array<IdValue>>;
  locales?: Maybe<Array<Locale>>;
  locations?: Maybe<Array<IdValue>>;
  newsletter?: Maybe<Array<IdValue>>;
  notificationTranslatedHashes?: Maybe<Array<IdKeyValue>>;
  phases?: Maybe<Array<Phase>>;
  pledgeCurrencies?: Maybe<Array<Currency>>;
  pledgeCurrency?: Maybe<Array<Currency>>;
  pledgeFrequencies?: Maybe<Array<IdKeyValue>>;
  pledgeFrequency?: Maybe<Array<IdKeyValue>>;
  pledgesReceived?: Maybe<Array<IdValue>>;
  preferredContactMethods?: Maybe<Array<IdKeyValue>>;
  sendAppeals?: Maybe<Array<IdValue>>;
  sendNewsletterOptions?: Maybe<Array<IdValue>>;
  status?: Maybe<Array<StatusesConstant>>;
  statuses?: Maybe<Array<StatusesConstant>>;
  times?: Maybe<Array<Time>>;
};

export type Contact = {
  __typename?: 'Contact';
  addresses: AddressConnection;
  avatar: Scalars['String']['output'];
  churchName?: Maybe<Scalars['String']['output']>;
  contactDonorAccounts: ContactDonorAccountConnection;
  contactPhase?: Maybe<PhaseEnum>;
  /** Contact Referrals referencing the Contacts that were referred by the current Contact (AKA "Referrals") */
  contactReferralsByMe: ReferralConnection;
  /** Contact Referrals referencing the Contacts that referred the current Contact (AKA "Referred By") */
  contactReferralsToMe: ReferralConnection;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Whether or not there are any people and they are all deceased */
  deceased: Scalars['Boolean']['output'];
  donations: DonationConnection;
  donorAccountIds?: Maybe<Array<Scalars['String']['output']>>;
  envelopeGreeting?: Maybe<Scalars['String']['output']>;
  greeting?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastDonation?: Maybe<Donation>;
  lateAt?: Maybe<Scalars['ISO8601Date']['output']>;
  likelyToGive?: Maybe<LikelyToGiveEnum>;
  locale?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nextAsk?: Maybe<Scalars['ISO8601Date']['output']>;
  noAppeals?: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not the Contact is eligible for Gift Aid (UK government subsidies) */
  noGiftAid?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  notesSavedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  people: PersonConnection;
  pledgeAmount?: Maybe<Scalars['Float']['output']>;
  pledgeCurrency?: Maybe<Scalars['String']['output']>;
  /** Attempted conversion of pledgeCurrency to symbol with fallback to pledgeCurrency value */
  pledgeCurrencySymbol?: Maybe<Scalars['String']['output']>;
  pledgeFrequency?: Maybe<PledgeFrequencyEnum>;
  pledgeReceived: Scalars['Boolean']['output'];
  pledgeStartDate?: Maybe<Scalars['ISO8601Date']['output']>;
  pledges: Array<Pledge>;
  preferredContactMethod?: Maybe<PreferredContactMethodEnum>;
  primaryAddress?: Maybe<Address>;
  /** Sets the address matching this id to primary_mailing_address true */
  primaryAddressId?: Maybe<Scalars['String']['output']>;
  primaryPerson?: Maybe<Person>;
  /** The remote system relationship code to match with */
  relationshipCode?: Maybe<Scalars['String']['output']>;
  sendNewsletter?: Maybe<SendNewsletterEnum>;
  source?: Maybe<ContactSourceEnum>;
  /** Indicates if a user has starred the contact */
  starred: Scalars['Boolean']['output'];
  /** Indicates when the contact was starred */
  starredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  status?: Maybe<StatusEnum>;
  statusValid?: Maybe<Scalars['Boolean']['output']>;
  /** json string of suggested changes to contact */
  suggestedChanges?: Maybe<Scalars['String']['output']>;
  tagList: Array<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  totalDonations?: Maybe<Scalars['Float']['output']>;
  /** The cached number of uncompleted tasks for this Contact */
  uncompletedTasksCount: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<UserScopedToAccountList>;
  website?: Maybe<Scalars['String']['output']>;
};

export type ContactAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactContactDonorAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactContactReferralsByMeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactContactReferralsToMeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactDonationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactPeopleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactAddresses = {
  __typename?: 'ContactAddresses';
  city?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  primaryMailingAddress?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ContactAnonymizeMutation */
export type ContactAnonymizeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
};

/** Autogenerated return type of ContactAnonymizeMutation */
export type ContactAnonymizeMutationPayload = {
  __typename?: 'ContactAnonymizeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact: Contact;
};

/** The connection type for Contact. */
export type ContactConnection = {
  __typename?: 'ContactConnection';
  /** A list of edges. */
  edges: Array<ContactEdge>;
  /** A list of nodes. */
  nodes: Array<Contact>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

export type ContactCreateInput = {
  churchName?: InputMaybe<Scalars['String']['input']>;
  contactReferralsByMe?: InputMaybe<Array<ContactReferralByMeInput>>;
  contactReferralsToMe?: InputMaybe<Array<ContactReferralToMeInput>>;
  envelopeGreeting?: InputMaybe<Scalars['String']['input']>;
  greeting?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  likelyToGive?: InputMaybe<LikelyToGiveEnum>;
  locale?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  nextAsk?: InputMaybe<Scalars['ISO8601Date']['input']>;
  noAppeals?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  pledgeAmount?: InputMaybe<Scalars['Float']['input']>;
  pledgeCurrency?: InputMaybe<Scalars['String']['input']>;
  pledgeFrequency?: InputMaybe<PledgeFrequencyEnum>;
  pledgeReceived?: InputMaybe<Scalars['Boolean']['input']>;
  pledgeStartDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  preferredContactMethod?: InputMaybe<PreferredContactMethodEnum>;
  sendNewsletter?: InputMaybe<SendNewsletterEnum>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<StatusEnum>;
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of ContactCreateMutation */
export type ContactCreateMutationInput = {
  /** AccountList ID that the contact is associated with */
  accountListId: Scalars['ID']['input'];
  /** attributes to create */
  attributes: ContactCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ContactCreateMutation */
export type ContactCreateMutationPayload = {
  __typename?: 'ContactCreateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact: Contact;
};

/** Autogenerated input type of ContactDeleteMutation */
export type ContactDeleteMutationInput = {
  /** AccountList ID that the contact is associated with */
  accountListId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of contact to delete */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ContactDeleteMutation */
export type ContactDeleteMutationPayload = {
  __typename?: 'ContactDeleteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type ContactDestroyDonorAccountInput = {
  contactId: Scalars['ID']['input'];
  donorAccountId: Scalars['ID']['input'];
};

export type ContactDestroyDonorAccountPayload = {
  __typename?: 'ContactDestroyDonorAccountPayload';
  id: Scalars['ID']['output'];
};

export type ContactDonorAccount = {
  __typename?: 'ContactDonorAccount';
  createdAt: Scalars['ISO8601DateTime']['output'];
  donorAccount: DonorAccount;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for ContactDonorAccount. */
export type ContactDonorAccountConnection = {
  __typename?: 'ContactDonorAccountConnection';
  /** A list of edges. */
  edges: Array<ContactDonorAccountEdge>;
  /** A list of nodes. */
  nodes: Array<ContactDonorAccount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ContactDonorAccountEdge = {
  __typename?: 'ContactDonorAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ContactDonorAccount>;
};

export type ContactDonorAccountInput = {
  accountNumber: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type ContactDonorAccountType = {
  accountNumber: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  organization?: InputMaybe<ContactDonorOrganizationType>;
  type: Scalars['String']['input'];
};

export type ContactDonorOrganizationType = {
  id: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export type ContactDuplicate = {
  __typename?: 'ContactDuplicate';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  ignore: Scalars['Boolean']['output'];
  reason: ReasonEnum;
  recordOne: Contact;
  recordTwo: Contact;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for ContactDuplicate. */
export type ContactDuplicateConnection = {
  __typename?: 'ContactDuplicateConnection';
  /** A list of edges. */
  edges: Array<ContactDuplicateEdge>;
  /** A list of nodes. */
  nodes: Array<ContactDuplicate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ContactDuplicateEdge = {
  __typename?: 'ContactDuplicateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ContactDuplicate>;
};

/** An edge in a connection. */
export type ContactEdge = {
  __typename?: 'ContactEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Contact>;
};

export enum ContactFilterActivityTypeEnum {
  Any = 'ANY',
  AppointmentInPerson = 'APPOINTMENT_IN_PERSON',
  AppointmentPhoneCall = 'APPOINTMENT_PHONE_CALL',
  AppointmentVideoCall = 'APPOINTMENT_VIDEO_CALL',
  FollowUpEmail = 'FOLLOW_UP_EMAIL',
  FollowUpInPerson = 'FOLLOW_UP_IN_PERSON',
  FollowUpPhoneCall = 'FOLLOW_UP_PHONE_CALL',
  FollowUpSocialMedia = 'FOLLOW_UP_SOCIAL_MEDIA',
  FollowUpTextMessage = 'FOLLOW_UP_TEXT_MESSAGE',
  InitiationEmail = 'INITIATION_EMAIL',
  InitiationInPerson = 'INITIATION_IN_PERSON',
  InitiationLetter = 'INITIATION_LETTER',
  InitiationPhoneCall = 'INITIATION_PHONE_CALL',
  InitiationSocialMedia = 'INITIATION_SOCIAL_MEDIA',
  InitiationSpecialGiftAppeal = 'INITIATION_SPECIAL_GIFT_APPEAL',
  InitiationTextMessage = 'INITIATION_TEXT_MESSAGE',
  /** special type when filtered by will return any task with no activityType */
  None = 'NONE',
  PartnerCareDigitalNewsletter = 'PARTNER_CARE_DIGITAL_NEWSLETTER',
  PartnerCareEmail = 'PARTNER_CARE_EMAIL',
  PartnerCareInPerson = 'PARTNER_CARE_IN_PERSON',
  PartnerCarePhoneCall = 'PARTNER_CARE_PHONE_CALL',
  PartnerCarePhysicalNewsletter = 'PARTNER_CARE_PHYSICAL_NEWSLETTER',
  PartnerCarePrayerRequest = 'PARTNER_CARE_PRAYER_REQUEST',
  PartnerCareSocialMedia = 'PARTNER_CARE_SOCIAL_MEDIA',
  PartnerCareTextMessage = 'PARTNER_CARE_TEXT_MESSAGE',
  PartnerCareThank = 'PARTNER_CARE_THANK',
  PartnerCareToDo = 'PARTNER_CARE_TO_DO',
  PartnerCareUpdateInformation = 'PARTNER_CARE_UPDATE_INFORMATION',
}

export enum ContactFilterNewsletterEnum {
  All = 'ALL',
  Both = 'BOTH',
  Email = 'EMAIL',
  EmailOnly = 'EMAIL_ONLY',
  None = 'NONE',
  NoValue = 'NO_VALUE',
  Physical = 'PHYSICAL',
  PhysicalOnly = 'PHYSICAL_ONLY',
}

export type ContactFilterNotesInput = {
  wildcardNoteSearch?: InputMaybe<Scalars['String']['input']>;
  wildcard_note_search?: InputMaybe<Scalars['String']['input']>;
};

export enum ContactFilterPledgeReceivedEnum {
  Any = 'ANY',
  NotReceived = 'NOT_RECEIVED',
  Received = 'RECEIVED',
}

export type ContactFilterSetInput = {
  /** Filter by Address No Longer Valid; Accepts values 'true', or 'false' */
  addressHistoric?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter contacts with addresses located within the lat lng bounds. Hash should contain a ne and sw lat lng pair. */
  addressLatLng?: InputMaybe<Scalars['String']['input']>;
  /** Filter where address value is false; accepts "false" */
  addressValid?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by alma mater; Accepts multiple parameters, with value 'none', or an alma mater name */
  almaMater?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter where contact people have anniversary on date between provided date range */
  anniversary?: InputMaybe<DateRangeInput>;
  /** When filtering by tags this specifies if the filter should match tasks with any of the filtered tags if "true" or only tasks with all of the filtered tags if "false" */
  anyTags?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by Appeal; Accepts multiple parameters, with value 'no_appeals', or an appeal ID */
  appeal?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Pledge Status. One of excluded, asked, not_received, received_not_processed, or processed. Must be used with appeal filter. */
  appealStatus?: InputMaybe<Scalars['String']['input']>;
  /** Filter where contact people have birthday on date between provided date range */
  birthday?: InputMaybe<DateRangeInput>;
  /** Filter by Church; Accepts multiple parameters, with value 'none', or a church name */
  church?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by City; Accepts multiple parameters, with value 'none', or a city name */
  city?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by where contact has address; Accepts values 'Yes', or 'No' */
  contactInfoAddr?: InputMaybe<Scalars['String']['input']>;
  /** Filter where contact people has an email; Accepts values 'Yes', or 'No' */
  contactInfoEmail?: InputMaybe<Scalars['String']['input']>;
  /** Filter where contact people has a Facebook Profile; Accepts values 'Yes', or 'No' */
  contactInfoFacebook?: InputMaybe<Scalars['String']['input']>;
  /** Filter where contact people has a mobile phone; Accepts values 'Yes', or 'No' */
  contactInfoMobile?: InputMaybe<Scalars['String']['input']>;
  /** Filter where contact people has a phone; Accepts values 'Yes', or 'No' */
  contactInfoPhone?: InputMaybe<Scalars['String']['input']>;
  /** Filter where contact people has a work phone; Accepts values 'Yes', or 'No' */
  contactInfoWorkPhone?: InputMaybe<Scalars['String']['input']>;
  /** Filter by Type; Accepts multiple parameters, with values 'person', and 'company' */
  contactType?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Country; Accepts multiple parameters, with values 'none', or a country */
  country?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Created At date between a provided date range */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Filter by Designation Account; accepts multiple parameters, separated by comma */
  designationAccountId?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by Gift Options; Accepts multiple parameters, with values 'none', 'one', 'first', and 'last' */
  donation?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Exact Gift Amount; Accepts multiple parameters, with values like '9.99' */
  donationAmount?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Gift Amount Range; Accepts object with max and min keys with decimal values { "max": "9.99", "min": "0.99" } */
  donationAmountRange?: InputMaybe<NumericRangeInput>;
  /** Filter by Gift Date; Accepts date range with text value like 'MM/DD/YYYY - MM/DD/YYYY' */
  donationDate?: InputMaybe<DateRangeInput>;
  /** Filter contacts that have any of the tags included in this filter; comma separated value for multiple */
  excludeTags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter where contact gave more than they pledged within the provided date range */
  gaveMoreThanPledgedRange?: InputMaybe<DateRangeInput>;
  /** Filter contacts with ids included in this filter; comma separated value for multiple */
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter contacts by the date at which the contact started being late on payments */
  lateAt?: InputMaybe<DateRangeInput>;
  /** Filter by Likely To Give; Accepts multiple parameters, with values 'none', 'Least Likely', 'Likely', and 'Most Likely' */
  likely?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Language; Accepts multiple parameters, */
  locale?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Metro Area; Accepts multiple parameters, with values 'none', or a metro area name */
  metroArea?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter where contact name starts with the provided value */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Filter by Newsletter Recipients; Accepts values 'none', 'all', 'address', 'email', and 'both' */
  newsletter?: InputMaybe<ContactFilterNewsletterEnum>;
  /** Filter by Next Ask date within the provided date range */
  nextAsk?: InputMaybe<DateRangeInput>;
  /** Filter where contact has asked specifically not to receive appeals; accepts "true" */
  noAppeals?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter contacts with note contents; accepts hash with wildcard_note_search parameter { "wildcard_note_search": "value to search by" } */
  notes?: InputMaybe<ContactFilterNotesInput>;
  /** Filter where at least one contact person has opted out from email; Accepts values 'Yes', or 'No' */
  optOut?: InputMaybe<Scalars['String']['input']>;
  /** filter where contact's account list has a designation account belonging to an organization; Accepts IDs separated by comma */
  organizationId?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter contacts by pledge status. completed, outstanding, pending */
  pledge?: InputMaybe<Scalars['String']['input']>;
  /** Filter by Pledge Amount; Accepts multiple parameters, with values like '100.0' */
  pledgeAmount?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter where Pledge Amount increased in the provided date range */
  pledgeAmountIncreasedRange?: InputMaybe<DateRangeInput>;
  /** Filter by Pledge Currency; Accepts multiple parameters, with values like 'USD' */
  pledgeCurrency?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Pledge Frequency; Accepts multiple parameters, with numeric values like '0.23076923076923' (Weekly), '0.46153846153846' (Every 2 Weeks), '1.0' (Monthly), '2.0' (Every 2 Months), '3.0', '4.0', '6.0', '12.0' (Yearly), and '24.0' (Every 2 Years) */
  pledgeFrequency?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Late By; Accepts values '', '0_30' (Less than 30 days late), '30_60' (More than 30 days late), '60_90' (More than 60 days late), or '90' (More than 90 days late) */
  pledgeLateBy?: InputMaybe<Scalars['String']['input']>;
  /** Filter by Pledge Received; Accepts values 'true', or 'false' */
  pledgeReceived?: InputMaybe<ContactFilterPledgeReceivedEnum>;
  /** Filter contacts with addresses in particular state; accepts values 'primary', 'active', 'inactive', or 'null' */
  primaryAddress?: InputMaybe<Scalars['String']['input']>;
  /** Filter by Referrer; Accepts multiple parameters, with values 'none', 'any', or a Contact ID */
  referrer?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Referrer ID; Accepts Comma separated list of Contact IDs */
  referrerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by Region; Accepts multiple parameters, with values 'none', or a region name */
  region?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Action; Accepts multiple parameters, with values 'none', or an activity type like 'Call' */
  relatedTaskAction?: InputMaybe<Array<ContactFilterActivityTypeEnum>>;
  reverseAlmaMater?: InputMaybe<Scalars['Boolean']['input']>;
  reverseAppeal?: InputMaybe<Scalars['Boolean']['input']>;
  reverseChurch?: InputMaybe<Scalars['Boolean']['input']>;
  reverseCity?: InputMaybe<Scalars['Boolean']['input']>;
  reverseContactType?: InputMaybe<Scalars['Boolean']['input']>;
  reverseCountry?: InputMaybe<Scalars['Boolean']['input']>;
  reverseDesignationAccountId?: InputMaybe<Scalars['Boolean']['input']>;
  reverseDonation?: InputMaybe<Scalars['Boolean']['input']>;
  reverseDonationAmount?: InputMaybe<Scalars['Boolean']['input']>;
  reverseIds?: InputMaybe<Scalars['Boolean']['input']>;
  reverseLikely?: InputMaybe<Scalars['Boolean']['input']>;
  reverseLocale?: InputMaybe<Scalars['Boolean']['input']>;
  reverseMetroArea?: InputMaybe<Scalars['Boolean']['input']>;
  reversePledgeAmount?: InputMaybe<Scalars['Boolean']['input']>;
  reversePledgeCurrency?: InputMaybe<Scalars['Boolean']['input']>;
  reversePledgeFrequency?: InputMaybe<Scalars['Boolean']['input']>;
  reverseReferrer?: InputMaybe<Scalars['Boolean']['input']>;
  reverseRegion?: InputMaybe<Scalars['Boolean']['input']>;
  reverseRelatedTaskAction?: InputMaybe<Scalars['Boolean']['input']>;
  reverseSource?: InputMaybe<Scalars['Boolean']['input']>;
  reverseState?: InputMaybe<Scalars['Boolean']['input']>;
  reverseStatus?: InputMaybe<Scalars['Boolean']['input']>;
  reverseTags?: InputMaybe<Scalars['Boolean']['input']>;
  reverseTimezone?: InputMaybe<Scalars['Boolean']['input']>;
  reverseUserIds?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by Source; Accepts multiple parameters */
  source?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by starred, is either selected or unselected */
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter where contact started giving in the provided date range */
  startedGivingRange?: InputMaybe<DateRangeInput>;
  /** Filter by State; Accepts multiple parameters, with values 'none', or a state */
  state?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Status; Accepts multiple parameters, with values 'active', 'hidden', 'null', 'Never Contacted', 'Ask in Future', 'Cultivate Relationship', 'Contact for Appointment', 'Appointment Scheduled', 'Call for Decision', 'Partner - Financial', 'Partner - Special', 'Partner - Pray', 'Not Interested', 'Unresponsive', 'Never Ask', 'Research Abandoned', and 'Expired Referral' */
  status?: InputMaybe<Array<ContactFilterStatusEnum>>;
  /** Filter by Status Valid; Accepts values 'true', or 'false' */
  statusValid?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter where contact stopped giving in the provided date range */
  stoppedGivingRange?: InputMaybe<DateRangeInput>;
  /** Filter by Tags; Accepts multiple parameters, with text values separated by comma */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Due Date; Accepts date range with text value like 'MM/DD/YYYY - MM/DD/YYYY' */
  taskDueDate?: InputMaybe<DateRangeInput>;
  /** Filter where contact's tasks have completed set to true; accepts "true" */
  tasksAllCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by Timezone; Accepts multiple parameters */
  timezone?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter contacts that were updated in this date range */
  updatedAt?: InputMaybe<DateTimeRangeInput>;
  /** Filter contacts with user_ids included in this filter; comma separated value for multiple */
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter contacts where subject contains this filter */
  wildcardSearch?: InputMaybe<Scalars['String']['input']>;
};

export enum ContactFilterStatusEnum {
  Active = 'ACTIVE',
  AppointmentScheduled = 'APPOINTMENT_SCHEDULED',
  AskInFuture = 'ASK_IN_FUTURE',
  CallForDecision = 'CALL_FOR_DECISION',
  ContactForAppointment = 'CONTACT_FOR_APPOINTMENT',
  CultivateRelationship = 'CULTIVATE_RELATIONSHIP',
  ExpiredReferral = 'EXPIRED_REFERRAL',
  Hidden = 'HIDDEN',
  NeverAsk = 'NEVER_ASK',
  NeverContacted = 'NEVER_CONTACTED',
  NotInterested = 'NOT_INTERESTED',
  Null = 'NULL',
  PartnerFinancial = 'PARTNER_FINANCIAL',
  PartnerPray = 'PARTNER_PRAY',
  PartnerSpecial = 'PARTNER_SPECIAL',
  ResearchAbandoned = 'RESEARCH_ABANDONED',
  ResearchContactInfo = 'RESEARCH_CONTACT_INFO',
  Unresponsive = 'UNRESPONSIVE',
}

export type ContactGroup = {
  __typename?: 'ContactGroup';
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  tag?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedInDbAt?: Maybe<Scalars['String']['output']>;
};

export type ContactPeople = {
  __typename?: 'ContactPeople';
  deceased?: Maybe<Scalars['Boolean']['output']>;
  emailAddresses?: Maybe<Array<Maybe<ContactPeopleEmailAddresses>>>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumbers?: Maybe<Array<Maybe<ContactPeoplePhoneNumbers>>>;
};

export type ContactPeopleAccountLists = {
  __typename?: 'ContactPeopleAccountLists';
  accountListUsers?: Maybe<Array<Maybe<ContactPeopleAccountListsUsers>>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ContactPeopleAccountListsUsers = {
  __typename?: 'ContactPeopleAccountListsUsers';
  id?: Maybe<Scalars['ID']['output']>;
  phoneNumbers?: Maybe<Array<Maybe<ContactPeoplePhoneNumbers>>>;
  userEmailAddresses?: Maybe<Array<Maybe<ContactPeopleEmailAddresses>>>;
  userFirstName?: Maybe<Scalars['String']['output']>;
  userLastName?: Maybe<Scalars['String']['output']>;
};

export type ContactPeopleEmailAddresses = {
  __typename?: 'ContactPeopleEmailAddresses';
  email?: Maybe<Scalars['String']['output']>;
  historic?: Maybe<Scalars['Boolean']['output']>;
  primary?: Maybe<Scalars['Boolean']['output']>;
};

export type ContactPeoplePhoneNumbers = {
  __typename?: 'ContactPeoplePhoneNumbers';
  historic?: Maybe<Scalars['Boolean']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  primary?: Maybe<Scalars['Boolean']['output']>;
};

export type ContactPrimaryAddress = {
  __typename?: 'ContactPrimaryAddress';
  addresses: Array<AddressWithPrimary>;
};

export type ContactPrimaryAddressInput = {
  contactId: Scalars['ID']['input'];
  primaryAddressId?: InputMaybe<Scalars['ID']['input']>;
};

export type ContactReferralByMeInput = {
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  referredToId?: InputMaybe<Scalars['ID']['input']>;
};

export type ContactReferralToMeInput = {
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  referredById?: InputMaybe<Scalars['ID']['input']>;
};

export enum ContactSortEnum {
  /** sorts by contacts with active status and name */
  ActiveStatusAndName = 'ACTIVE_STATUS_AND_NAME',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum ContactSourceEnum {
  GiveSite = 'GIVE_SITE',
  Mpdx = 'MPDX',
}

export type ContactUpdateInput = {
  churchName?: InputMaybe<Scalars['String']['input']>;
  contactReferralsByMe?: InputMaybe<Array<ContactReferralByMeInput>>;
  contactReferralsToMe?: InputMaybe<Array<ContactReferralToMeInput>>;
  donorAccount?: InputMaybe<ContactDonorAccountInput>;
  donorAccountIds?: InputMaybe<Array<Scalars['String']['input']>>;
  envelopeGreeting?: InputMaybe<Scalars['String']['input']>;
  greeting?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  likelyToGive?: InputMaybe<LikelyToGiveEnum>;
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextAsk?: InputMaybe<Scalars['ISO8601Date']['input']>;
  noAppeals?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  pledgeAmount?: InputMaybe<Scalars['Float']['input']>;
  pledgeCurrency?: InputMaybe<Scalars['String']['input']>;
  pledgeFrequency?: InputMaybe<PledgeFrequencyEnum>;
  pledgeReceived?: InputMaybe<Scalars['Boolean']['input']>;
  pledgeStartDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  preferredContactMethod?: InputMaybe<PreferredContactMethodEnum>;
  primaryAddressId?: InputMaybe<Scalars['String']['input']>;
  primaryPersonId?: InputMaybe<Scalars['ID']['input']>;
  sendNewsletter?: InputMaybe<SendNewsletterEnum>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<StatusEnum>;
  statusValid?: InputMaybe<Scalars['Boolean']['input']>;
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of ContactUpdateMutation */
export type ContactUpdateMutationInput = {
  /** AccountList ID that the contact is associated with */
  accountListId: Scalars['ID']['input'];
  /** attributes to update */
  attributes: ContactUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ContactUpdateMutation */
export type ContactUpdateMutationPayload = {
  __typename?: 'ContactUpdateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact: Contact;
};

export type ContactsAccountListAnalytics = {
  __typename?: 'ContactsAccountListAnalytics';
  active: Scalars['Int']['output'];
  referrals: Scalars['Int']['output'];
  referralsOnHand: Scalars['Int']['output'];
};

export type ContactsByStatus = {
  __typename?: 'ContactsByStatus';
  appointmentScheduled: Scalars['Int']['output'];
  callForDecision: Scalars['Int']['output'];
  connectionsRemaining: Scalars['Int']['output'];
  contactForAppointment: Scalars['Int']['output'];
  cultivate: Scalars['Int']['output'];
  financial: Scalars['Int']['output'];
  future: Scalars['Int']['output'];
  initiations: Scalars['Int']['output'];
  neverContacted: Scalars['Int']['output'];
  prayer: Scalars['Int']['output'];
  special: Scalars['Int']['output'];
};

/** Autogenerated input type of ContactsUpdateMutation */
export type ContactsUpdateMutationInput = {
  /** AccountList ID that the contacts are associated with */
  accountListId: Scalars['ID']['input'];
  /** attributes to update */
  attributes: Array<ContactUpdateInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ContactsUpdateMutation */
export type ContactsUpdateMutationPayload = {
  __typename?: 'ContactsUpdateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contacts: Array<Contact>;
};

export type CorrespondenceAccountListAnalytics = {
  __typename?: 'CorrespondenceAccountListAnalytics';
  newsletters: Scalars['Int']['output'];
  precall: Scalars['Int']['output'];
  reminders: Scalars['Int']['output'];
  supportLetters: Scalars['Int']['output'];
  thankYous: Scalars['Int']['output'];
};

/** Autogenerated input type of CreateAccountListInvite */
export type CreateAccountListInviteInput = {
  attributes: AccountListInviteCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateAccountListInvite */
export type CreateAccountListInvitePayload = {
  __typename?: 'CreateAccountListInvitePayload';
  accountListInvite: AccountListInvite;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateGoogleIntegrationInput = {
  accountListId: Scalars['String']['input'];
  googleAccountId: Scalars['ID']['input'];
  googleIntegration?: InputMaybe<GoogleAccountIntegrationInput>;
};

/** Autogenerated input type of CreateOrUpdateOptionMutation */
export type CreateOrUpdateOptionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** key to update or create */
  key: Scalars['String']['input'];
  /** value to change */
  value: Scalars['String']['input'];
};

/** Autogenerated return type of CreateOrUpdateOptionMutation */
export type CreateOrUpdateOptionMutationPayload = {
  __typename?: 'CreateOrUpdateOptionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  option: Option;
};

export type CreateOrganizationInviteInput = {
  organizationId: Scalars['ID']['input'];
  recipientEmail: Scalars['ID']['input'];
};

export type Currency = {
  __typename?: 'Currency';
  code?: Maybe<Scalars['String']['output']>;
  codeSymbolString?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** Range of dates */
export type DateRangeInput = {
  /** Maximum value of the range */
  max?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Minimum value of the range */
  min?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

export type DateRangeOption = {
  __typename?: 'DateRangeOption';
  name: Scalars['String']['output'];
  rangeEnd: Scalars['ISO8601DateTime']['output'];
  rangeStart: Scalars['ISO8601DateTime']['output'];
};

/** Range of dates */
export type DateTimeRangeInput = {
  /** Maximum value of the range */
  max?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Minimum value of the range */
  min?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

export type DaterangeFilter = Filter & {
  __typename?: 'DaterangeFilter';
  filterKey: Scalars['String']['output'];
  options?: Maybe<Array<DateRangeOption>>;
  title: Scalars['String']['output'];
};

export type DeleteGoogleAccountInput = {
  accountId: Scalars['ID']['input'];
};

export type DeleteMailchimpAccountInput = {
  accountListId: Scalars['ID']['input'];
};

export type DeleteOrganizationAdminInput = {
  adminId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type DeleteOrganizationContactInput = {
  contactId: Scalars['ID']['input'];
};

export type DeleteOrganizationInviteInput = {
  inviteId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type DeletePrayerlettersAccountInput = {
  accountListId: Scalars['ID']['input'];
};

export type DeleteTagsInput = {
  page: Scalars['String']['input'];
  tagName: Scalars['String']['input'];
};

export type DeleteTagsPayload = {
  __typename?: 'DeleteTagsPayload';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeletionResponse = {
  __typename?: 'DeletionResponse';
  success: Scalars['Boolean']['output'];
};

export type DesignationAccount = {
  __typename?: 'DesignationAccount';
  accountNumber: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organization: Organization;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type DesignationAccountRest = {
  __typename?: 'DesignationAccountRest';
  active: Scalars['Boolean']['output'];
  balanceUpdatedAt: Scalars['ISO8601Date']['output'];
  convertedBalance: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  designationNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DesignationAccountsGroup = {
  __typename?: 'DesignationAccountsGroup';
  designationAccounts: Array<DesignationAccountRest>;
  organizationName: Scalars['String']['output'];
};

export type DesignationWithDisplayName = {
  __typename?: 'DesignationWithDisplayName';
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export enum DisplayMethodEnum {
  /** announcement should be displayed as a banner across the top of the screen */
  Banner = 'banner',
  /** announcement should be displayed as a popup modal */
  Modal = 'modal',
}

export enum DisplayResultEnum {
  AppointmentResultCancelled = 'APPOINTMENT_RESULT_CANCELLED',
  AppointmentResultFollowUp = 'APPOINTMENT_RESULT_FOLLOW_UP',
  AppointmentResultNotInterested = 'APPOINTMENT_RESULT_NOT_INTERESTED',
  AppointmentResultPartnerFinancial = 'APPOINTMENT_RESULT_PARTNER_FINANCIAL',
  AppointmentResultPartnerPray = 'APPOINTMENT_RESULT_PARTNER_PRAY',
  AppointmentResultPartnerSpecial = 'APPOINTMENT_RESULT_PARTNER_SPECIAL',
  FollowUpResultNotInterested = 'FOLLOW_UP_RESULT_NOT_INTERESTED',
  FollowUpResultNoResponse = 'FOLLOW_UP_RESULT_NO_RESPONSE',
  FollowUpResultPartnerFinancial = 'FOLLOW_UP_RESULT_PARTNER_FINANCIAL',
  FollowUpResultPartnerPray = 'FOLLOW_UP_RESULT_PARTNER_PRAY',
  FollowUpResultPartnerSpecial = 'FOLLOW_UP_RESULT_PARTNER_SPECIAL',
  InitiationResultAppointmentScheduled = 'INITIATION_RESULT_APPOINTMENT_SCHEDULED',
  InitiationResultCircleBack = 'INITIATION_RESULT_CIRCLE_BACK',
  InitiationResultNotInterested = 'INITIATION_RESULT_NOT_INTERESTED',
  InitiationResultNoResponse = 'INITIATION_RESULT_NO_RESPONSE',
  PartnerCareCompleted = 'PARTNER_CARE_COMPLETED',
}

export type Donation = {
  __typename?: 'Donation';
  amount: Money;
  appeal?: Maybe<Appeal>;
  appealAmount?: Maybe<Money>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  designationAccount: DesignationAccount;
  donationDate: Scalars['ISO8601Date']['output'];
  donorAccount: DonorAccount;
  id: Scalars['ID']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  remoteId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Donation. */
export type DonationConnection = {
  __typename?: 'DonationConnection';
  /** A list of edges. */
  edges: Array<DonationEdge>;
  /** A list of nodes. */
  nodes: Array<Donation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

export type DonationCreateInput = {
  amount: Scalars['Float']['input'];
  appealAmount?: InputMaybe<Scalars['Float']['input']>;
  appealId?: InputMaybe<Scalars['ID']['input']>;
  currency: Scalars['String']['input'];
  designationAccountId: Scalars['ID']['input'];
  donationDate: Scalars['ISO8601Date']['input'];
  donorAccountId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  motivation?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of DonationCreateMutation */
export type DonationCreateMutationInput = {
  /** AccountList ID that the contact is associated with */
  accountListId: Scalars['ID']['input'];
  /** attributes to create */
  attributes: DonationCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DonationCreateMutation */
export type DonationCreateMutationPayload = {
  __typename?: 'DonationCreateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donation: Donation;
};

/** Autogenerated input type of DonationDeleteMutation */
export type DonationDeleteMutationInput = {
  /** AccountList ID that the donation is associated with */
  accountListId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of donation to delete */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DonationDeleteMutation */
export type DonationDeleteMutationPayload = {
  __typename?: 'DonationDeleteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

/** An edge in a connection. */
export type DonationEdge = {
  __typename?: 'DonationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Donation>;
};

export type DonationHistories = {
  __typename?: 'DonationHistories';
  /** total divided by number of periods */
  average: Scalars['Float']['output'];
  /** total divided by number of periods except current period */
  averageIgnoreCurrent: Scalars['Float']['output'];
  /** total divided by number of periods except current period or periods where total was zero */
  averageIgnoreCurrentAndZero: Scalars['Float']['output'];
  /** total divided by number of periods except periods where total was zero */
  averageIgnoreZero: Scalars['Float']['output'];
  periods: Array<DonationHistoriesPeriod>;
};

export type DonationHistoriesPeriod = {
  __typename?: 'DonationHistoriesPeriod';
  convertedTotal: Scalars['Float']['output'];
  endDate: Scalars['ISO8601Date']['output'];
  startDate: Scalars['ISO8601Date']['output'];
  totals: Array<Total>;
};

export type DonationUpdateInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  appealAmount?: InputMaybe<Scalars['Float']['input']>;
  appealId?: InputMaybe<Scalars['ID']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  designationAccountId?: InputMaybe<Scalars['ID']['input']>;
  donationDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  donorAccountId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  memo?: InputMaybe<Scalars['String']['input']>;
  motivation?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of DonationUpdateMutation */
export type DonationUpdateMutationInput = {
  /** AccountList ID that the donation is associated with */
  accountListId: Scalars['ID']['input'];
  /** attributes to update */
  attributes: DonationUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DonationUpdateMutation */
export type DonationUpdateMutationPayload = {
  __typename?: 'DonationUpdateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donation: Donation;
};

/** Autogenerated input type of DonationsUpdateMutation */
export type DonationsUpdateMutationInput = {
  /** AccountList ID that the donations are associated with */
  accountListId: Scalars['ID']['input'];
  /** attributes to update */
  attributes: Array<DonationUpdateInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DonationsUpdateMutation */
export type DonationsUpdateMutationPayload = {
  __typename?: 'DonationsUpdateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donations: Array<Donation>;
};

export type DonorAccount = {
  __typename?: 'DonorAccount';
  accountNumber: Scalars['String']['output'];
  /** Contacts associated with the donor account, scoped to the account list of the current context */
  contacts: ContactConnection;
  createdAt: Scalars['ISO8601DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organization: Organization;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type DonorAccountContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DuplicateRecordPair = {
  __typename?: 'DuplicateRecordPair';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  ignore: Scalars['Boolean']['output'];
  reason: ReasonEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type DuplicateRecordPairUpdateInput = {
  /** Whether or not this duplicate pair should be ignored */
  ignore: Scalars['Boolean']['input'];
  /** The reason this duplicate pair was found */
  reason?: InputMaybe<ReasonEnum>;
};

/** Autogenerated input type of DuplicatesUpdateMutation */
export type DuplicatesUpdateMutationInput = {
  /** attributes to set on the duplicate record */
  attributes: DuplicateRecordPairUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the duplicate to operate on */
  id: Scalars['ID']['input'];
  /** The type of duplicate record pair */
  type: TypeEnum;
};

/** Autogenerated return type of DuplicatesUpdateMutation */
export type DuplicatesUpdateMutationPayload = {
  __typename?: 'DuplicatesUpdateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  duplicate: DuplicateRecordPair;
};

export type ElectronicAccountListAnalytics = {
  __typename?: 'ElectronicAccountListAnalytics';
  appointments: Scalars['Int']['output'];
  received: Scalars['Int']['output'];
  sent: Scalars['Int']['output'];
};

export type EmailAddress = {
  __typename?: 'EmailAddress';
  createdAt: Scalars['ISO8601DateTime']['output'];
  email: Scalars['String']['output'];
  historic: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  primary: Scalars['Boolean']['output'];
  /** The source of the email address, for example "MPDX" if it was entered by a user, or "Siebel" if it was imported from the US Donation System */
  source: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  validValues: Scalars['Boolean']['output'];
};

/** The connection type for EmailAddress. */
export type EmailAddressConnection = {
  __typename?: 'EmailAddressConnection';
  /** A list of edges. */
  edges: Array<EmailAddressEdge>;
  /** A list of nodes. */
  nodes: Array<EmailAddress>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EmailAddressEdge = {
  __typename?: 'EmailAddressEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EmailAddress>;
};

export type EmailsAccountListAnalytics = {
  __typename?: 'EmailsAccountListAnalytics';
  received: Scalars['Int']['output'];
  sent: Scalars['Int']['output'];
};

export type EntryHistoriesGroup = {
  __typename?: 'EntryHistoriesGroup';
  entryHistories?: Maybe<Array<EntryHistoryRest>>;
  financialAccountId: Scalars['ID']['output'];
};

export type EntryHistoryRest = {
  __typename?: 'EntryHistoryRest';
  closingBalance: Scalars['Float']['output'];
  endDate: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type ExcludedAppealContact = {
  __typename?: 'ExcludedAppealContact';
  contact?: Maybe<Contact>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  reasons?: Maybe<Array<ExcludedAppealContactReasonEnum>>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum ExcludedAppealContactReasonEnum {
  GaveMoreThanPledgedRange = 'gave_more_than_pledged_range',
  IncreasedRecently = 'increased_recently',
  JoinedRecently = 'joined_recently',
  MarkedDoNotAsk = 'marked_do_not_ask',
  NoAppeals = 'no_appeals',
  PledgeAmountIncreasedRange = 'pledge_amount_increased_range',
  PledgeLateBy = 'pledge_late_by',
  SpecialGift = 'special_gift',
  StartedGivingRange = 'started_giving_range',
  StoppedGiving = 'stopped_giving',
  StoppedGivingRange = 'stopped_giving_range',
}

export type ExpectedMonthlyTotalDonation = {
  __typename?: 'ExpectedMonthlyTotalDonation';
  contactId?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  contactStatus?: Maybe<Scalars['String']['output']>;
  convertedAmount?: Maybe<Scalars['Float']['output']>;
  convertedCurrency?: Maybe<Scalars['String']['output']>;
  convertedCurrencySymbol?: Maybe<Scalars['String']['output']>;
  donationAmount?: Maybe<Scalars['Float']['output']>;
  donationCurrency?: Maybe<Scalars['String']['output']>;
  donationCurrencySymbol?: Maybe<Scalars['String']['output']>;
  pledgeAmount?: Maybe<Scalars['Float']['output']>;
  pledgeCurrency?: Maybe<Scalars['String']['output']>;
  pledgeCurrencySymbol?: Maybe<Scalars['String']['output']>;
  pledgeFrequency?: Maybe<Scalars['String']['output']>;
};

export type ExpectedMonthlyTotalGroup = {
  __typename?: 'ExpectedMonthlyTotalGroup';
  donations: Array<ExpectedMonthlyTotalDonation>;
  total: Scalars['Float']['output'];
};

export type ExpectedMonthlyTotalReport = {
  __typename?: 'ExpectedMonthlyTotalReport';
  currency?: Maybe<Scalars['String']['output']>;
  currencySymbol?: Maybe<Scalars['String']['output']>;
  likely: ExpectedMonthlyTotalGroup;
  received: ExpectedMonthlyTotalGroup;
  unlikely: ExpectedMonthlyTotalGroup;
};

export type ExportContactsInput = {
  accountListId: Scalars['ID']['input'];
  /** Enum value to determine the file format of the exported contacts (Either csv, xlsx, or pdf) */
  format: ExportFormatEnum;
  labelType?: InputMaybe<ExportLabelTypeEnum>;
  /** Boolean value to determine if export is going to be used for mailing purposes. */
  mailing: Scalars['Boolean']['input'];
  sort?: InputMaybe<ExportSortEnum>;
};

export type ExportDataInput = {
  accountListId: Scalars['ID']['input'];
};

export enum ExportFormatEnum {
  Csv = 'csv',
  Pdf = 'pdf',
  Xlsx = 'xlsx',
}

export enum ExportLabelTypeEnum {
  Avery5160 = 'Avery5160',
  Avery7160 = 'Avery7160',
}

export enum ExportSortEnum {
  Name = 'name',
  Zip = 'zip',
}

export type FacebookAccount = {
  __typename?: 'FacebookAccount';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

/** The connection type for FacebookAccount. */
export type FacebookAccountConnection = {
  __typename?: 'FacebookAccountConnection';
  /** A list of edges. */
  edges: Array<FacebookAccountEdge>;
  /** A list of nodes. */
  nodes: Array<FacebookAccount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FacebookAccountEdge = {
  __typename?: 'FacebookAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FacebookAccount>;
};

export type FacebookAccountListAnalytics = {
  __typename?: 'FacebookAccountListAnalytics';
  received: Scalars['Int']['output'];
  sent: Scalars['Int']['output'];
};

export type FamilyRelationship = {
  __typename?: 'FamilyRelationship';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  relatedPerson: Person;
  relationship: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for FamilyRelationship. */
export type FamilyRelationshipConnection = {
  __typename?: 'FamilyRelationshipConnection';
  /** A list of edges. */
  edges: Array<FamilyRelationshipEdge>;
  /** A list of nodes. */
  nodes: Array<FamilyRelationship>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FamilyRelationshipEdge = {
  __typename?: 'FamilyRelationshipEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FamilyRelationship>;
};

export type Filter = {
  filterKey: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type FilterGroup = {
  __typename?: 'FilterGroup';
  featured?: Maybe<Scalars['Boolean']['output']>;
  filters: Array<Filter>;
  name: Scalars['String']['output'];
};

export type FilterOption = {
  __typename?: 'FilterOption';
  name: Scalars['String']['output'];
  placeholder?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type FinancialAccount = {
  __typename?: 'FinancialAccount';
  active: Scalars['Boolean']['output'];
  balance: Money;
  categories: CategoryConnection;
  code: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type FinancialAccountCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FinancialAccountCategory = {
  __typename?: 'FinancialAccountCategory';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** The connection type for FinancialAccount. */
export type FinancialAccountConnection = {
  __typename?: 'FinancialAccountConnection';
  /** A list of edges. */
  edges: Array<FinancialAccountEdge>;
  /** A list of nodes. */
  nodes: Array<FinancialAccount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FinancialAccountEdge = {
  __typename?: 'FinancialAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FinancialAccount>;
};

export type FinancialAccountEntriesInput = {
  accountListId: Scalars['ID']['input'];
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  dateRange: Scalars['String']['input'];
  financialAccountId: Scalars['ID']['input'];
  wildcardSearch?: InputMaybe<Scalars['String']['input']>;
};

export type FinancialAccountEntriesResponse = {
  __typename?: 'FinancialAccountEntriesResponse';
  entries: Array<FinancialAccountEntry>;
  metaData: FinancialAccountMetaData;
};

export type FinancialAccountEntry = {
  __typename?: 'FinancialAccountEntry';
  amount: Scalars['String']['output'];
  category: FinancialAccountCategory;
  code: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  entryDate: Scalars['ISO8601Date']['output'];
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type FinancialAccountMetaData = {
  __typename?: 'FinancialAccountMetaData';
  closingBalance?: Maybe<Scalars['String']['output']>;
  credits?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  debits?: Maybe<Scalars['String']['output']>;
  difference?: Maybe<Scalars['String']['output']>;
  filter?: Maybe<Scalars['String']['output']>;
  openingBalance?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<Scalars['String']['output']>;
};

export type FinancialAccountSummaryCategory = {
  __typename?: 'FinancialAccountSummaryCategory';
  amount?: Maybe<Scalars['String']['output']>;
  category?: Maybe<FinancialAccountCategory>;
  id: Scalars['ID']['output'];
};

export type FinancialAccountSummaryInput = {
  accountListId: Scalars['ID']['input'];
  financialAccountId: Scalars['ID']['input'];
};

export type FinancialAccountSummaryResponse = {
  __typename?: 'FinancialAccountSummaryResponse';
  closingBalance?: Maybe<Scalars['String']['output']>;
  creditByCategories: Array<Maybe<FinancialAccountSummaryCategory>>;
  credits?: Maybe<Scalars['String']['output']>;
  debitByCategories: Array<Maybe<FinancialAccountSummaryCategory>>;
  debits?: Maybe<Scalars['String']['output']>;
  difference?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  openingBalance?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['String']['output'];
};

export type FourteenMonthReport = {
  __typename?: 'FourteenMonthReport';
  currencyGroups: Array<FourteenMonthReportCurrencyGroup>;
  currencyType?: Maybe<FourteenMonthReportCurrencyType>;
  salaryCurrency: Scalars['String']['output'];
};

export type FourteenMonthReportContact = {
  __typename?: 'FourteenMonthReportContact';
  accountNumbers: Array<Scalars['String']['output']>;
  average: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  lateBy30Days: Scalars['Boolean']['output'];
  lateBy60Days: Scalars['Boolean']['output'];
  minimum: Scalars['Float']['output'];
  months: Array<FourteenMonthReportContactMonths>;
  name: Scalars['String']['output'];
  pledgeAmount?: Maybe<Scalars['Float']['output']>;
  pledgeCurrency?: Maybe<Scalars['String']['output']>;
  pledgeFrequency?: Maybe<Scalars['String']['output']>;
  status?: Maybe<StatusEnum>;
  total: Scalars['Float']['output'];
};

export type FourteenMonthReportContactDonation = {
  __typename?: 'FourteenMonthReportContactDonation';
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  date: Scalars['ISO8601Date']['output'];
  paymentMethod?: Maybe<Scalars['String']['output']>;
};

export type FourteenMonthReportContactMonths = {
  __typename?: 'FourteenMonthReportContactMonths';
  donations: Array<FourteenMonthReportContactDonation>;
  month: Scalars['ISO8601Date']['output'];
  salaryCurrencyTotal: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type FourteenMonthReportCurrencyGroup = {
  __typename?: 'FourteenMonthReportCurrencyGroup';
  contacts: Array<FourteenMonthReportContact>;
  currency: Scalars['String']['output'];
  totals: FourteenMonthReportCurrencyGroupTotals;
};

export type FourteenMonthReportCurrencyGroupTotals = {
  __typename?: 'FourteenMonthReportCurrencyGroupTotals';
  average: Scalars['Float']['output'];
  minimum: Scalars['Float']['output'];
  months: Array<FourteenMonthReportCurrencyGroupTotalsMonth>;
  year: Scalars['Float']['output'];
};

export type FourteenMonthReportCurrencyGroupTotalsMonth = {
  __typename?: 'FourteenMonthReportCurrencyGroupTotalsMonth';
  month: Scalars['ISO8601Date']['output'];
  total: Scalars['Float']['output'];
};

export enum FourteenMonthReportCurrencyType {
  Donor = 'donor',
  Salary = 'salary',
}

export type GoogleAccountAttributes = {
  __typename?: 'GoogleAccountAttributes';
  contactGroups: Array<Maybe<ContactGroup>>;
  createdAt: Scalars['String']['output'];
  email: Scalars['String']['output'];
  expiresAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastDownload?: Maybe<Scalars['String']['output']>;
  lastEmailSync?: Maybe<Scalars['String']['output']>;
  primary: Scalars['Boolean']['output'];
  remoteId: Scalars['String']['output'];
  tokenExpired: Scalars['Boolean']['output'];
  updatedAt: Scalars['String']['output'];
  updatedInDbAt: Scalars['String']['output'];
};

export type GoogleAccountIntegration = {
  __typename?: 'GoogleAccountIntegration';
  calendarId?: Maybe<Scalars['String']['output']>;
  calendarIntegration?: Maybe<Scalars['Boolean']['output']>;
  calendarIntegrations: Array<ActivityTypeEnum>;
  calendarName?: Maybe<Scalars['String']['output']>;
  calendars: Array<Maybe<GoogleAccountIntegrationCalendars>>;
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  updatedInDbAt: Scalars['String']['output'];
};

export type GoogleAccountIntegrationCalendars = {
  __typename?: 'GoogleAccountIntegrationCalendars';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GoogleAccountIntegrationCalendarsInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GoogleAccountIntegrationInput = {
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarIntegration?: InputMaybe<Scalars['Boolean']['input']>;
  calendarIntegrations?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  calendarName?: InputMaybe<Scalars['String']['input']>;
  calendars?: InputMaybe<
    Array<InputMaybe<GoogleAccountIntegrationCalendarsInput>>
  >;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  overwrite?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  updatedInDbAt?: InputMaybe<Scalars['String']['input']>;
};

export type GoogleAccountIntegrationsInput = {
  accountListId: Scalars['ID']['input'];
  googleAccountId: Scalars['ID']['input'];
};

export type IdKeyValue = {
  __typename?: 'IdKeyValue';
  id?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type IdValue = {
  __typename?: 'IdValue';
  id?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Invite = {
  __typename?: 'Invite';
  acceptedAt?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inviteUserAs?: Maybe<Scalars['String']['output']>;
  recipientEmail?: Maybe<Scalars['String']['output']>;
};

export enum InviteTypeEnum {
  Coach = 'COACH',
  User = 'USER',
}

export type KeyAccount = {
  __typename?: 'KeyAccount';
  createdAt: Scalars['ISO8601DateTime']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum LikelyToGiveEnum {
  LeastLikely = 'LEAST_LIKELY',
  Likely = 'LIKELY',
  MostLikely = 'MOST_LIKELY',
}

export type LinkedinAccount = {
  __typename?: 'LinkedinAccount';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  publicUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for LinkedinAccount. */
export type LinkedinAccountConnection = {
  __typename?: 'LinkedinAccountConnection';
  /** A list of edges. */
  edges: Array<LinkedinAccountEdge>;
  /** A list of nodes. */
  nodes: Array<LinkedinAccount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LinkedinAccountEdge = {
  __typename?: 'LinkedinAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LinkedinAccount>;
};

export type ListsAvailableForNewsletters = {
  __typename?: 'ListsAvailableForNewsletters';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Locale = {
  __typename?: 'Locale';
  englishName?: Maybe<Scalars['String']['output']>;
  nativeName?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
};

export type MailchimpAccount = {
  __typename?: 'MailchimpAccount';
  active: Scalars['Boolean']['output'];
  autoLogCampaigns: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  listsAvailableForNewsletters?: Maybe<
    Array<Maybe<ListsAvailableForNewsletters>>
  >;
  listsLink: Scalars['String']['output'];
  listsPresent: Scalars['Boolean']['output'];
  primaryListId?: Maybe<Scalars['ID']['output']>;
  primaryListName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedInDbAt: Scalars['String']['output'];
  valid: Scalars['Boolean']['output'];
  validateKey: Scalars['Boolean']['output'];
  validationError?: Maybe<Scalars['String']['output']>;
};

export type MailchimpAccountInput = {
  accountListId: Scalars['ID']['input'];
};

export type MergeContactsInput = {
  winnersAndLosers: Array<WinnersAndLosers>;
};

export type MergePeopleBulkInput = {
  winnersAndLosers: Array<WinnersAndLosers>;
};

/** Autogenerated input type of MergePeopleMutation */
export type MergePeopleMutationInput = {
  /** AccountList ID that the people are associated with */
  accountListId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** IDs of the people that will lose the merge */
  loserIds: Array<Scalars['ID']['input']>;
  /** ID of the person that will win the merge */
  winnerId: Scalars['ID']['input'];
};

/** Autogenerated return type of MergePeopleMutation */
export type MergePeopleMutationPayload = {
  __typename?: 'MergePeopleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact: Contact;
  winner: Person;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Float']['output'];
  conversionDate: Scalars['ISO8601Date']['output'];
  conversionRate: Scalars['Float']['output'];
  convertedAmount: Scalars['Float']['output'];
  convertedCurrency: Scalars['String']['output'];
  currency: Scalars['String']['output'];
};

export type MultiselectFilter = Filter & {
  __typename?: 'MultiselectFilter';
  defaultSelection?: Maybe<Scalars['String']['output']>;
  filterKey: Scalars['String']['output'];
  options?: Maybe<Array<FilterOption>>;
  title: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acknowledgeAllUserNotifications?: Maybe<AcknowledgeAllUserNotificationsMutationPayload>;
  acknowledgeAnnouncement?: Maybe<AcknowledgeAnnouncementMutationPayload>;
  acknowledgeUserNotification?: Maybe<AcknowledgeUserNotificationMutationPayload>;
  adminDeleteOrganizationCoach: DeletionResponse;
  adminDeleteOrganizationInvite: DeletionResponse;
  adminDeleteOrganizationUser: DeletionResponse;
  anonymizeContact?: Maybe<ContactAnonymizeMutationPayload>;
  apiOauthSignIn?: Maybe<ApiOauthSignInMutationPayload>;
  assignContactsToAppeal?: Maybe<AssignContactsToAppealMutationPayload>;
  cancelAccountListInvite?: Maybe<CancelAccountListInvitePayload>;
  createAccountListInvite?: Maybe<CreateAccountListInvitePayload>;
  createAccountListPledge?: Maybe<AccountListPledgeCreateMutationPayload>;
  createAddress?: Maybe<AddressCreateMutationPayload>;
  createAppeal?: Maybe<AppealCreateMutationPayload>;
  createContact?: Maybe<ContactCreateMutationPayload>;
  createDonation?: Maybe<DonationCreateMutationPayload>;
  createGoogleIntegration: GoogleAccountIntegration;
  createOrUpdateUserOption?: Maybe<CreateOrUpdateOptionMutationPayload>;
  createOrganizationAccount?: Maybe<OrganizationAccountCreateMutationPayload>;
  createOrganizationInvite: Invite;
  createPerson?: Maybe<PersonCreateMutationPayload>;
  createTask?: Maybe<TaskCreateMutationPayload>;
  createTaskComment?: Maybe<TaskCommentCreateMutationPayload>;
  createTasks?: Maybe<TasksCreateMutationPayload>;
  deleteAccountListCoach?: Maybe<AccountListCoachDeleteMutationPayload>;
  deleteAccountListPledge?: Maybe<AccountListPledgeDeleteMutationPayload>;
  deleteAccountListUser?: Maybe<AccountListUserDeleteMutationPayload>;
  deleteAddress?: Maybe<AddressDeleteMutationPayload>;
  deleteAppeal?: Maybe<AppealDeleteMutationPayload>;
  deleteAppealContact?: Maybe<AppealContactDeleteMutationPayload>;
  deleteComment: CommentDeleteMutationPayload;
  deleteContact?: Maybe<ContactDeleteMutationPayload>;
  deleteDonation?: Maybe<DonationDeleteMutationPayload>;
  deleteGoogleAccount: DeletionResponse;
  deleteMailchimpAccount: DeletionResponse;
  deleteOrganizationAccount?: Maybe<OrganizationAccountDeleteMutationPayload>;
  deleteOrganizationContact: DeletionResponse;
  deletePerson?: Maybe<PersonDeleteMutationPayload>;
  deletePrayerlettersAccount: DeletionResponse;
  deleteTags?: Maybe<DeleteTagsPayload>;
  deleteTask?: Maybe<TaskDeleteMutationPayload>;
  deleteTasks?: Maybe<TasksDeleteMutationPayload>;
  deleteUser?: Maybe<UserDeleteMutationPayload>;
  deleteUserOption?: Maybe<UserOptionDeleteMutationPayload>;
  destroyDonorAccount?: Maybe<ContactDestroyDonorAccountPayload>;
  destroyOrganizationAdmin: DeletionResponse;
  destroyOrganizationInvite: DeletionResponse;
  exportContacts: Scalars['String']['output'];
  exportData: Scalars['String']['output'];
  mergeAccountList?: Maybe<AccountListMergeMutationPayload>;
  /** Returns the ids of the winner */
  mergeContacts: Array<Scalars['ID']['output']>;
  mergePeople?: Maybe<MergePeopleMutationPayload>;
  mergePeopleBulk: Array<Scalars['ID']['output']>;
  oktaSignIn?: Maybe<OktaSignInMutationPayload>;
  resetAccountList?: Maybe<AccountListResetMutationPayload>;
  saveCoachingAnswer: CoachingAnswer;
  sendToChalkline: Scalars['String']['output'];
  setActiveDesignationAccount: DesignationAccountRest;
  setActiveFinancialAccount: SetActiveFinancialAccountRest;
  setContactPrimaryAddress: ContactPrimaryAddress;
  syncGoogleAccount?: Maybe<Scalars['String']['output']>;
  syncMailchimpAccount?: Maybe<Scalars['String']['output']>;
  syncOrganizationAccount?: Maybe<OrganizationAccountSyncMutationPayload>;
  syncPrayerlettersAccount?: Maybe<Scalars['String']['output']>;
  updateAccountList?: Maybe<AccountListUpdateMutationPayload>;
  updateAccountListPledge?: Maybe<AccountListPledgeUpdateMutationPayload>;
  updateAddress?: Maybe<AddressUpdateMutationPayload>;
  updateAppeal?: Maybe<AppealUpdateMutationPayload>;
  updateComment: CommentUpdateMutationPayload;
  updateContact?: Maybe<ContactUpdateMutationPayload>;
  updateContacts?: Maybe<ContactsUpdateMutationPayload>;
  updateDonation?: Maybe<DonationUpdateMutationPayload>;
  updateDonations?: Maybe<DonationsUpdateMutationPayload>;
  updateDuplicate?: Maybe<DuplicatesUpdateMutationPayload>;
  updateGoogleIntegration: GoogleAccountIntegration;
  updateMailchimpAccount: MailchimpAccount;
  updateNotificationPreferences?: Maybe<NotificationPreferencesUpdateMutationPayload>;
  updateOrganizationAccount?: Maybe<OrganizationAccountUpdateMutationPayload>;
  updatePeople?: Maybe<PeopleUpdateMutationPayload>;
  updatePerson?: Maybe<PersonUpdateMutationPayload>;
  updatePreference?: Maybe<UserPreferenceUpdateMutationPayload>;
  updateTask?: Maybe<TaskUpdateMutationPayload>;
  updateTasks?: Maybe<TasksUpdateMutationPayload>;
  updateUser?: Maybe<UserUpdateMutationPayload>;
  userKeySignIn?: Maybe<UserKeySignInMutationPayload>;
};

export type MutationAcknowledgeAllUserNotificationsArgs = {
  input: AcknowledgeAllUserNotificationsMutationInput;
};

export type MutationAcknowledgeAnnouncementArgs = {
  input: AcknowledgeAnnouncementMutationInput;
};

export type MutationAcknowledgeUserNotificationArgs = {
  input: AcknowledgeUserNotificationMutationInput;
};

export type MutationAdminDeleteOrganizationCoachArgs = {
  input: AdminDeleteOrganizationCoachInput;
};

export type MutationAdminDeleteOrganizationInviteArgs = {
  input: AdminDeleteOrganizationInviteInput;
};

export type MutationAdminDeleteOrganizationUserArgs = {
  input: AdminDeleteOrganizationUserInput;
};

export type MutationAnonymizeContactArgs = {
  input: ContactAnonymizeMutationInput;
};

export type MutationApiOauthSignInArgs = {
  input: ApiOauthSignInMutationInput;
};

export type MutationAssignContactsToAppealArgs = {
  input: AssignContactsToAppealMutationInput;
};

export type MutationCancelAccountListInviteArgs = {
  input: CancelAccountListInviteInput;
};

export type MutationCreateAccountListInviteArgs = {
  input: CreateAccountListInviteInput;
};

export type MutationCreateAccountListPledgeArgs = {
  input: AccountListPledgeCreateMutationInput;
};

export type MutationCreateAddressArgs = {
  input: AddressCreateMutationInput;
};

export type MutationCreateAppealArgs = {
  input: AppealCreateMutationInput;
};

export type MutationCreateContactArgs = {
  input: ContactCreateMutationInput;
};

export type MutationCreateDonationArgs = {
  input: DonationCreateMutationInput;
};

export type MutationCreateGoogleIntegrationArgs = {
  input: CreateGoogleIntegrationInput;
};

export type MutationCreateOrUpdateUserOptionArgs = {
  input: CreateOrUpdateOptionMutationInput;
};

export type MutationCreateOrganizationAccountArgs = {
  input: OrganizationAccountCreateMutationInput;
};

export type MutationCreateOrganizationInviteArgs = {
  input: CreateOrganizationInviteInput;
};

export type MutationCreatePersonArgs = {
  input: PersonCreateMutationInput;
};

export type MutationCreateTaskArgs = {
  input: TaskCreateMutationInput;
};

export type MutationCreateTaskCommentArgs = {
  input: TaskCommentCreateMutationInput;
};

export type MutationCreateTasksArgs = {
  input: TasksCreateMutationInput;
};

export type MutationDeleteAccountListCoachArgs = {
  input: AccountListCoachDeleteMutationInput;
};

export type MutationDeleteAccountListPledgeArgs = {
  input: AccountListPledgeDeleteMutationInput;
};

export type MutationDeleteAccountListUserArgs = {
  input: AccountListUserDeleteMutationInput;
};

export type MutationDeleteAddressArgs = {
  input: AddressDeleteMutationInput;
};

export type MutationDeleteAppealArgs = {
  input: AppealDeleteMutationInput;
};

export type MutationDeleteAppealContactArgs = {
  input: AppealContactDeleteMutationInput;
};

export type MutationDeleteCommentArgs = {
  input: CommentDeleteMutationInput;
};

export type MutationDeleteContactArgs = {
  input: ContactDeleteMutationInput;
};

export type MutationDeleteDonationArgs = {
  input: DonationDeleteMutationInput;
};

export type MutationDeleteGoogleAccountArgs = {
  input: DeleteGoogleAccountInput;
};

export type MutationDeleteMailchimpAccountArgs = {
  input: DeleteMailchimpAccountInput;
};

export type MutationDeleteOrganizationAccountArgs = {
  input: OrganizationAccountDeleteMutationInput;
};

export type MutationDeleteOrganizationContactArgs = {
  input: DeleteOrganizationContactInput;
};

export type MutationDeletePersonArgs = {
  input: PersonDeleteMutationInput;
};

export type MutationDeletePrayerlettersAccountArgs = {
  input: DeletePrayerlettersAccountInput;
};

export type MutationDeleteTagsArgs = {
  input: DeleteTagsInput;
};

export type MutationDeleteTaskArgs = {
  input: TaskDeleteMutationInput;
};

export type MutationDeleteTasksArgs = {
  input: TasksDeleteMutationInput;
};

export type MutationDeleteUserArgs = {
  input: UserDeleteMutationInput;
};

export type MutationDeleteUserOptionArgs = {
  input: UserOptionDeleteMutationInput;
};

export type MutationDestroyDonorAccountArgs = {
  input: ContactDestroyDonorAccountInput;
};

export type MutationDestroyOrganizationAdminArgs = {
  input: DeleteOrganizationAdminInput;
};

export type MutationDestroyOrganizationInviteArgs = {
  input: DeleteOrganizationInviteInput;
};

export type MutationExportContactsArgs = {
  input: ExportContactsInput;
};

export type MutationExportDataArgs = {
  input: ExportDataInput;
};

export type MutationMergeAccountListArgs = {
  input: AccountListMergeMutationInput;
};

export type MutationMergeContactsArgs = {
  input: MergeContactsInput;
};

export type MutationMergePeopleArgs = {
  input: MergePeopleMutationInput;
};

export type MutationMergePeopleBulkArgs = {
  input: MergePeopleBulkInput;
};

export type MutationOktaSignInArgs = {
  input: OktaSignInMutationInput;
};

export type MutationResetAccountListArgs = {
  input: AccountListResetMutationInput;
};

export type MutationSaveCoachingAnswerArgs = {
  input: SaveCoachingAnswerInput;
};

export type MutationSendToChalklineArgs = {
  input: SendToChalklineInput;
};

export type MutationSetActiveDesignationAccountArgs = {
  input: SetActiveDesignationAccountInput;
};

export type MutationSetActiveFinancialAccountArgs = {
  input: SetActiveFinancialAccountInput;
};

export type MutationSetContactPrimaryAddressArgs = {
  input: ContactPrimaryAddressInput;
};

export type MutationSyncGoogleAccountArgs = {
  input: SyncGoogleAccountInput;
};

export type MutationSyncMailchimpAccountArgs = {
  input: SyncMailchimpAccountInput;
};

export type MutationSyncOrganizationAccountArgs = {
  input: OrganizationAccountSyncMutationInput;
};

export type MutationSyncPrayerlettersAccountArgs = {
  input: SyncPrayerlettersAccountInput;
};

export type MutationUpdateAccountListArgs = {
  input: AccountListUpdateMutationInput;
};

export type MutationUpdateAccountListPledgeArgs = {
  input: AccountListPledgeUpdateMutationInput;
};

export type MutationUpdateAddressArgs = {
  input: AddressUpdateMutationInput;
};

export type MutationUpdateAppealArgs = {
  input: AppealUpdateMutationInput;
};

export type MutationUpdateCommentArgs = {
  input: CommentUpdateMutationInput;
};

export type MutationUpdateContactArgs = {
  input: ContactUpdateMutationInput;
};

export type MutationUpdateContactsArgs = {
  input: ContactsUpdateMutationInput;
};

export type MutationUpdateDonationArgs = {
  input: DonationUpdateMutationInput;
};

export type MutationUpdateDonationsArgs = {
  input: DonationsUpdateMutationInput;
};

export type MutationUpdateDuplicateArgs = {
  input: DuplicatesUpdateMutationInput;
};

export type MutationUpdateGoogleIntegrationArgs = {
  input: UpdateGoogleIntegrationInput;
};

export type MutationUpdateMailchimpAccountArgs = {
  input: UpdateMailchimpAccountInput;
};

export type MutationUpdateNotificationPreferencesArgs = {
  input: NotificationPreferencesUpdateMutationInput;
};

export type MutationUpdateOrganizationAccountArgs = {
  input: OrganizationAccountUpdateMutationInput;
};

export type MutationUpdatePeopleArgs = {
  input: PeopleUpdateMutationInput;
};

export type MutationUpdatePersonArgs = {
  input: PersonUpdateMutationInput;
};

export type MutationUpdatePreferenceArgs = {
  input: UserPreferenceUpdateMutationInput;
};

export type MutationUpdateTaskArgs = {
  input: TaskUpdateMutationInput;
};

export type MutationUpdateTasksArgs = {
  input: TasksUpdateMutationInput;
};

export type MutationUpdateUserArgs = {
  input: UserUpdateMutationInput;
};

export type MutationUserKeySignInArgs = {
  input: UserKeySignInMutationInput;
};

export type Notification = {
  __typename?: 'Notification';
  cleared: Scalars['Boolean']['output'];
  contact: Contact;
  createdAt: Scalars['ISO8601DateTime']['output'];
  donation?: Maybe<Donation>;
  id: Scalars['ID']['output'];
  notificationType: NotificationType;
  occurredAt: Scalars['ISO8601DateTime']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for UserNotification. */
export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  /** A list of edges. */
  edges: Array<NotificationEdge>;
  /** A list of nodes. */
  nodes: Array<UserNotification>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
  unreadCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserNotification>;
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  accountListId: Scalars['String']['output'];
  app: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  email: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  notificationType: NotificationType;
  task: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

/** The connection type for NotificationPreference. */
export type NotificationPreferenceConnection = {
  __typename?: 'NotificationPreferenceConnection';
  /** A list of edges. */
  edges: Array<NotificationPreferenceEdge>;
  /** A list of nodes. */
  nodes: Array<NotificationPreference>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type NotificationPreferenceEdge = {
  __typename?: 'NotificationPreferenceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<NotificationPreference>;
};

export type NotificationPreferenceUpdateInput = {
  app?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  notificationType: NotificationTypeTypeEnum;
  task?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of NotificationPreferencesUpdateMutation */
export type NotificationPreferencesUpdateMutationInput = {
  accountListId: Scalars['ID']['input'];
  attributes: Array<NotificationPreferenceUpdateInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of NotificationPreferencesUpdateMutation */
export type NotificationPreferencesUpdateMutationPayload = {
  __typename?: 'NotificationPreferencesUpdateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  notificationPreferences: Array<NotificationPreference>;
};

export enum NotificationTimeUnitEnum {
  Days = 'DAYS',
  Hours = 'HOURS',
  Minutes = 'MINUTES',
}

export type NotificationType = {
  __typename?: 'NotificationType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  descriptionTemplate: Scalars['String']['output'];
  emailDescriptionTemplate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: NotificationTypeTypeEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userNotificationTitleTemplate: Scalars['String']['output'];
};

export enum NotificationTypeEnum {
  Both = 'BOTH',
  Email = 'EMAIL',
  Mobile = 'MOBILE',
}

export enum NotificationTypeTypeEnum {
  CallPartnerOncePerYear = 'CALL_PARTNER_ONCE_PER_YEAR',
  LargerGift = 'LARGER_GIFT',
  LongTimeFrameGift = 'LONG_TIME_FRAME_GIFT',
  MissingAddressInNewsletter = 'MISSING_ADDRESS_IN_NEWSLETTER',
  MissingEmailInNewsletter = 'MISSING_EMAIL_IN_NEWSLETTER',
  NewAddress = 'NEW_ADDRESS',
  NewDesignationAccountSubscription = 'NEW_DESIGNATION_ACCOUNT_SUBSCRIPTION',
  NewPageSubscription = 'NEW_PAGE_SUBSCRIPTION',
  NewPartnerDuplicateMerged = 'NEW_PARTNER_DUPLICATE_MERGED',
  NewPartnerDuplicateNotMerged = 'NEW_PARTNER_DUPLICATE_NOT_MERGED',
  NewPartnerNoDuplicate = 'NEW_PARTNER_NO_DUPLICATE',
  RecontinuingGift = 'RECONTINUING_GIFT',
  RemindPartnerInAdvance = 'REMIND_PARTNER_IN_ADVANCE',
  SmallerGift = 'SMALLER_GIFT',
  SpecialGift = 'SPECIAL_GIFT',
  StartedGiving = 'STARTED_GIVING',
  StoppedGiving = 'STOPPED_GIVING',
  ThankPartnerOncePerYear = 'THANK_PARTNER_ONCE_PER_YEAR',
  UpcomingAnniversary = 'UPCOMING_ANNIVERSARY',
  UpcomingBirthday = 'UPCOMING_BIRTHDAY',
}

export type NumericRangeFilter = Filter & {
  __typename?: 'NumericRangeFilter';
  filterKey: Scalars['String']['output'];
  max: Scalars['Float']['output'];
  maxLabel: Scalars['String']['output'];
  min: Scalars['Float']['output'];
  minLabel: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** Range of numbers */
export type NumericRangeInput = {
  /** Maximum value of the range */
  max?: InputMaybe<Scalars['Float']['input']>;
  /** Minimum value of the range */
  min?: InputMaybe<Scalars['Float']['input']>;
};

/** Autogenerated input type of OktaSignInMutation */
export type OktaSignInMutationInput = {
  accessToken: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OktaSignInMutation */
export type OktaSignInMutationPayload = {
  __typename?: 'OktaSignInMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type Option = {
  __typename?: 'Option';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type Organization = {
  __typename?: 'Organization';
  apiClass: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  disableNewUsers?: Maybe<Scalars['Boolean']['output']>;
  giftAidPercentage?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  oauth: Scalars['Boolean']['output'];
  organizationType?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type OrganizationAccount = {
  __typename?: 'OrganizationAccount';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  lastDownload?: Maybe<Scalars['ISO8601DateTime']['output']>;
  lastDownloadAttemptAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  lastDownloadedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  latestDonationDate?: Maybe<Scalars['ISO8601Date']['output']>;
  organization: Organization;
  person: Person;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type OrganizationAccountCreateInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of OrganizationAccountCreateMutation */
export type OrganizationAccountCreateMutationInput = {
  /** attributes to create OrganizationAccount */
  attributes: OrganizationAccountCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OrganizationAccountCreateMutation */
export type OrganizationAccountCreateMutationPayload = {
  __typename?: 'OrganizationAccountCreateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organizationAccount: OrganizationAccount;
};

/** Autogenerated input type of OrganizationAccountDeleteMutation */
export type OrganizationAccountDeleteMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of OrganizationAccount to delete */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of OrganizationAccountDeleteMutation */
export type OrganizationAccountDeleteMutationPayload = {
  __typename?: 'OrganizationAccountDeleteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type OrganizationAccountListCoaches = {
  __typename?: 'OrganizationAccountListCoaches';
  coachEmailAddresses?: Maybe<Array<Maybe<AccountListEmailAddresses>>>;
  coachFirstName?: Maybe<Scalars['String']['output']>;
  coachLastName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated input type of OrganizationAccountSyncMutation */
export type OrganizationAccountSyncMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** OrganizationAccount id */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of OrganizationAccountSyncMutation */
export type OrganizationAccountSyncMutationPayload = {
  __typename?: 'OrganizationAccountSyncMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organizationAccount: OrganizationAccount;
};

export type OrganizationAccountUpdateInput = {
  id: Scalars['ID']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of OrganizationAccountUpdateMutation */
export type OrganizationAccountUpdateMutationInput = {
  /** attributes to update OrganizationAccount */
  attributes: OrganizationAccountUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OrganizationAccountUpdateMutation */
export type OrganizationAccountUpdateMutationPayload = {
  __typename?: 'OrganizationAccountUpdateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organizationAccount: OrganizationAccount;
};

export type OrganizationAdminsInput = {
  organizationId: Scalars['ID']['input'];
};

/** The connection type for Organization. */
export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  /** A list of edges. */
  edges: Array<OrganizationEdge>;
  /** A list of nodes. */
  nodes: Array<Organization>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrganizationEdge = {
  __typename?: 'OrganizationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Organization>;
};

export type OrganizationInvitesInput = {
  organizationId: Scalars['ID']['input'];
};

export type Organizations = {
  __typename?: 'Organizations';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type OrganizationsAccountList = {
  __typename?: 'OrganizationsAccountList';
  accountListCoachInvites?: Maybe<Array<Maybe<AccountListInvites>>>;
  accountListCoaches?: Maybe<Array<Maybe<OrganizationAccountListCoaches>>>;
  accountListUsers?: Maybe<Array<Maybe<AccountListUsers>>>;
  accountListUsersInvites?: Maybe<Array<Maybe<AccountListInvites>>>;
  designationAccounts?: Maybe<Array<Maybe<AccountListDesignationAccounts>>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationCount?: Maybe<Scalars['Int']['output']>;
};

export type OrganizationsContact = {
  __typename?: 'OrganizationsContact';
  accountList?: Maybe<ContactPeopleAccountLists>;
  addresses: Array<Maybe<ContactAddresses>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  people: Array<Maybe<ContactPeople>>;
  squareAvatar?: Maybe<Scalars['String']['output']>;
};

export type OverdueOrDueTodayTaskAnalytic = {
  __typename?: 'OverdueOrDueTodayTaskAnalytic';
  count: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PartnerGivingAnalysisReport = {
  __typename?: 'PartnerGivingAnalysisReport';
  contacts: Array<PartnerGivingAnalysisReportContact>;
  pagination: PartnerGivingAnalysisReportPagination;
  totalContacts: Scalars['Int']['output'];
};

export type PartnerGivingAnalysisReportContact = {
  __typename?: 'PartnerGivingAnalysisReportContact';
  donationPeriodAverage: Scalars['Float']['output'];
  donationPeriodCount: Scalars['Int']['output'];
  donationPeriodSum: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  lastDonationAmount: Scalars['Float']['output'];
  lastDonationCurrency: Scalars['String']['output'];
  lastDonationDate: Scalars['ISO8601Date']['output'];
  name: Scalars['String']['output'];
  pledgeCurrency: Scalars['String']['output'];
  totalDonations: Scalars['Float']['output'];
};

export type PartnerGivingAnalysisReportInput = {
  accountListId: Scalars['ID']['input'];
  contactFilters?: InputMaybe<ReportContactFilterSetInput>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  sortDirection: SortDirection;
  sortField: Scalars['String']['input'];
};

export type PartnerGivingAnalysisReportPagination = {
  __typename?: 'PartnerGivingAnalysisReportPagination';
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type People = {
  __typename?: 'People';
  periods: Array<PeoplePeriod>;
};

export type PeoplePeriod = {
  __typename?: 'PeoplePeriod';
  endDate: Scalars['ISO8601Date']['output'];
  people: Array<PersonWithParentContact>;
  startDate: Scalars['ISO8601Date']['output'];
};

/** Autogenerated input type of PeopleUpdateMutation */
export type PeopleUpdateMutationInput = {
  /** AccountList ID that the people are associated with */
  accountListId: Scalars['ID']['input'];
  /** attributes to update */
  attributes: Array<PersonUpdateInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PeopleUpdateMutation */
export type PeopleUpdateMutationPayload = {
  __typename?: 'PeopleUpdateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  people: Array<Person>;
};

export type Person = {
  __typename?: 'Person';
  almaMater?: Maybe<Scalars['String']['output']>;
  anniversaryDay?: Maybe<Scalars['Int']['output']>;
  anniversaryMonth?: Maybe<Scalars['Int']['output']>;
  anniversaryYear?: Maybe<Scalars['Int']['output']>;
  avatar: Scalars['String']['output'];
  birthdayDay?: Maybe<Scalars['Int']['output']>;
  birthdayMonth?: Maybe<Scalars['Int']['output']>;
  birthdayYear?: Maybe<Scalars['Int']['output']>;
  contactId: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  deceased: Scalars['Boolean']['output'];
  emailAddresses: EmailAddressConnection;
  employer?: Maybe<Scalars['String']['output']>;
  facebookAccounts: FacebookAccountConnection;
  familyRelationships: FamilyRelationshipConnection;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  legalFirstName?: Maybe<Scalars['String']['output']>;
  linkedinAccounts: LinkedinAccountConnection;
  maritalStatus?: Maybe<Scalars['String']['output']>;
  occupation?: Maybe<Scalars['String']['output']>;
  optoutEnewsletter: Scalars['Boolean']['output'];
  phoneNumbers: PhoneNumberConnection;
  primaryEmailAddress?: Maybe<EmailAddress>;
  primaryPhoneNumber?: Maybe<PhoneNumber>;
  suffix?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  twitterAccounts: TwitterAccountConnection;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  websites: WebsiteConnection;
};

export type PersonEmailAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonFacebookAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonFamilyRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonLinkedinAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonPhoneNumbersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonTwitterAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonWebsitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Person. */
export type PersonConnection = {
  __typename?: 'PersonConnection';
  /** A list of edges. */
  edges: Array<PersonEdge>;
  /** A list of nodes. */
  nodes: Array<Person>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

export type PersonCreateInput = {
  almaMater?: InputMaybe<Scalars['String']['input']>;
  anniversaryDay?: InputMaybe<Scalars['Int']['input']>;
  anniversaryMonth?: InputMaybe<Scalars['Int']['input']>;
  anniversaryYear?: InputMaybe<Scalars['Int']['input']>;
  birthdayDay?: InputMaybe<Scalars['Int']['input']>;
  birthdayMonth?: InputMaybe<Scalars['Int']['input']>;
  birthdayYear?: InputMaybe<Scalars['Int']['input']>;
  contactId: Scalars['ID']['input'];
  deceased?: InputMaybe<Scalars['Boolean']['input']>;
  emailAddresses?: InputMaybe<Array<PersonEmailAddressInput>>;
  employer?: InputMaybe<Scalars['String']['input']>;
  facebookAccounts?: InputMaybe<Array<PersonFacebookAccountInput>>;
  familyRelationships?: InputMaybe<Array<PersonFamilyRelationshipInput>>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  legalFirstName?: InputMaybe<Scalars['String']['input']>;
  linkedinAccounts?: InputMaybe<Array<PersonLinkedinAccountInput>>;
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  optoutEnewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumbers?: InputMaybe<Array<PersonPhoneNumberInput>>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twitterAccounts?: InputMaybe<Array<PersonTwitterAccountInput>>;
  websites?: InputMaybe<Array<PersonWebsiteInput>>;
};

/** Autogenerated input type of PersonCreateMutation */
export type PersonCreateMutationInput = {
  /** AccountList ID that the person is associated with */
  accountListId: Scalars['ID']['input'];
  /** attributes to create */
  attributes: PersonCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PersonCreateMutation */
export type PersonCreateMutationPayload = {
  __typename?: 'PersonCreateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  person: Person;
};

/** Autogenerated input type of PersonDeleteMutation */
export type PersonDeleteMutationInput = {
  /** AccountList ID that the person is associated with */
  accountListId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of person to delete */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PersonDeleteMutation */
export type PersonDeleteMutationPayload = {
  __typename?: 'PersonDeleteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type PersonDuplicate = {
  __typename?: 'PersonDuplicate';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  ignore: Scalars['Boolean']['output'];
  reason: ReasonEnum;
  recordOne: Person;
  recordTwo: Person;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for PersonDuplicate. */
export type PersonDuplicateConnection = {
  __typename?: 'PersonDuplicateConnection';
  /** A list of edges. */
  edges: Array<PersonDuplicateEdge>;
  /** A list of nodes. */
  nodes: Array<PersonDuplicate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PersonDuplicateEdge = {
  __typename?: 'PersonDuplicateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PersonDuplicate>;
};

/** An edge in a connection. */
export type PersonEdge = {
  __typename?: 'PersonEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Person>;
};

export type PersonEmailAddressInput = {
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  historic?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  validValues?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PersonFacebookAccountInput = {
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type PersonFamilyRelationshipInput = {
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  relatedPersonId?: InputMaybe<Scalars['ID']['input']>;
  relationship?: InputMaybe<Scalars['String']['input']>;
};

export type PersonFilterSetInput = {
  contactIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by Contact Status; Accepts multiple parameters, with values 'active', 'hidden', 'null', 'Never Contacted', 'Ask in Future', 'Cultivate Relationship', 'Contact for Appointment', 'Appointment Scheduled', 'Call for Decision', 'Partner - Financial', 'Partner - Special', 'Partner - Pray', 'Not Interested', 'Unresponsive', 'Never Ask', 'Research Abandoned', and 'Expired Referral' */
  contactStatus?: InputMaybe<Array<ContactFilterStatusEnum>>;
  /** Filter by deceased; Accepts values "true" and "false" */
  deceased?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Filter by Email Address Invalid; A Person's Email
   * Address is invalid if the Email Address's valid_values
   * attribute is set to false, or if the Person has
   * multiple Email Addresses marked as primary;
   * Accepts value "false"
   *
   */
  emailAddressValid?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Filter by Phone Number Invalid; A Person's Phone
   * Number is invalid if the Phone Number's valid_values
   * attribute is set to false, or if the Person has
   * multiple Phone Numbers marked as primary;
   * Accepts value "false"
   *
   */
  phoneNumberValid?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by Updated At Date range; Accepts date range with text value like "MM/DD/YYYY - MM/DD/YYYY" */
  updatedAt?: InputMaybe<DateTimeRangeInput>;
  /** Filter by keyword, searches through name, email_addresses and phone_numbers */
  wildcardSearch?: InputMaybe<Scalars['String']['input']>;
};

export type PersonLinkedinAccountInput = {
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  publicUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PersonPhoneNumberInput = {
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
  historic?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  validValues?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PersonTwitterAccountInput = {
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  screenName?: InputMaybe<Scalars['String']['input']>;
};

export type PersonUpdateInput = {
  almaMater?: InputMaybe<Scalars['String']['input']>;
  anniversaryDay?: InputMaybe<Scalars['Int']['input']>;
  anniversaryMonth?: InputMaybe<Scalars['Int']['input']>;
  anniversaryYear?: InputMaybe<Scalars['Int']['input']>;
  birthdayDay?: InputMaybe<Scalars['Int']['input']>;
  birthdayMonth?: InputMaybe<Scalars['Int']['input']>;
  birthdayYear?: InputMaybe<Scalars['Int']['input']>;
  deceased?: InputMaybe<Scalars['Boolean']['input']>;
  emailAddresses?: InputMaybe<Array<PersonEmailAddressInput>>;
  employer?: InputMaybe<Scalars['String']['input']>;
  facebookAccounts?: InputMaybe<Array<PersonFacebookAccountInput>>;
  familyRelationships?: InputMaybe<Array<PersonFamilyRelationshipInput>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  legalFirstName?: InputMaybe<Scalars['String']['input']>;
  linkedinAccounts?: InputMaybe<Array<PersonLinkedinAccountInput>>;
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  optoutEnewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumbers?: InputMaybe<Array<PersonPhoneNumberInput>>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twitterAccounts?: InputMaybe<Array<PersonTwitterAccountInput>>;
  websites?: InputMaybe<Array<PersonWebsiteInput>>;
};

/** Autogenerated input type of PersonUpdateMutation */
export type PersonUpdateMutationInput = {
  /** AccountList ID that the person is associated with */
  accountListId: Scalars['ID']['input'];
  /** attributes to update */
  attributes: PersonUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PersonUpdateMutation */
export type PersonUpdateMutationPayload = {
  __typename?: 'PersonUpdateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  person: Person;
};

export type PersonWebsiteInput = {
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type PersonWithParentContact = {
  __typename?: 'PersonWithParentContact';
  almaMater?: Maybe<Scalars['String']['output']>;
  anniversaryDay?: Maybe<Scalars['Int']['output']>;
  anniversaryMonth?: Maybe<Scalars['Int']['output']>;
  anniversaryYear?: Maybe<Scalars['Int']['output']>;
  avatar: Scalars['String']['output'];
  birthdayDay?: Maybe<Scalars['Int']['output']>;
  birthdayMonth?: Maybe<Scalars['Int']['output']>;
  birthdayYear?: Maybe<Scalars['Int']['output']>;
  contactId: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  deceased: Scalars['Boolean']['output'];
  emailAddresses: EmailAddressConnection;
  employer?: Maybe<Scalars['String']['output']>;
  facebookAccounts: FacebookAccountConnection;
  familyRelationships: FamilyRelationshipConnection;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  legalFirstName?: Maybe<Scalars['String']['output']>;
  linkedinAccounts: LinkedinAccountConnection;
  maritalStatus?: Maybe<Scalars['String']['output']>;
  occupation?: Maybe<Scalars['String']['output']>;
  optoutEnewsletter: Scalars['Boolean']['output'];
  parentContact: Contact;
  phoneNumbers: PhoneNumberConnection;
  primaryEmailAddress?: Maybe<EmailAddress>;
  primaryPhoneNumber?: Maybe<PhoneNumber>;
  suffix?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  twitterAccounts: TwitterAccountConnection;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  websites: WebsiteConnection;
};

export type PersonWithParentContactEmailAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonWithParentContactFacebookAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonWithParentContactFamilyRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonWithParentContactLinkedinAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonWithParentContactPhoneNumbersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonWithParentContactTwitterAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonWithParentContactWebsitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Phase = {
  __typename?: 'Phase';
  contactStatuses: Array<StatusEnum>;
  id: PhaseEnum;
  name: Scalars['String']['output'];
  results?: Maybe<Result>;
  tasks?: Maybe<Array<ActivityTypeEnum>>;
};

export enum PhaseEnum {
  Appointment = 'APPOINTMENT',
  Archive = 'ARCHIVE',
  Connection = 'CONNECTION',
  FollowUp = 'FOLLOW_UP',
  Initiation = 'INITIATION',
  PartnerCare = 'PARTNER_CARE',
}

export type PhoneAccountListAnalytics = {
  __typename?: 'PhoneAccountListAnalytics';
  appointments: Scalars['Int']['output'];
  attempted: Scalars['Int']['output'];
  completed: Scalars['Int']['output'];
  received: Scalars['Int']['output'];
  talkToInPerson: Scalars['Int']['output'];
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  createdAt: Scalars['ISO8601DateTime']['output'];
  historic: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  primary: Scalars['Boolean']['output'];
  /** The source of the email address, for example "MPDX" if it was entered by a user, or "Siebel" if it was imported from the US Donation System */
  source: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  validValues: Scalars['Boolean']['output'];
};

/** The connection type for PhoneNumber. */
export type PhoneNumberConnection = {
  __typename?: 'PhoneNumberConnection';
  /** A list of edges. */
  edges: Array<PhoneNumberEdge>;
  /** A list of nodes. */
  nodes: Array<PhoneNumber>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PhoneNumberEdge = {
  __typename?: 'PhoneNumberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PhoneNumber>;
};

export type Pledge = {
  __typename?: 'Pledge';
  amount: Scalars['Float']['output'];
  amountCurrency?: Maybe<Scalars['String']['output']>;
  appeal: Appeal;
  contact: Contact;
  createdAt: Scalars['ISO8601DateTime']['output'];
  expectedDate: Scalars['ISO8601Date']['output'];
  id: Scalars['ID']['output'];
  status?: Maybe<PledgeStatusEnum>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Pledge. */
export type PledgeConnection = {
  __typename?: 'PledgeConnection';
  /** A list of edges. */
  edges: Array<PledgeEdge>;
  /** A list of nodes. */
  nodes: Array<Pledge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

export type PledgeCreateInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountCurrency?: InputMaybe<Scalars['String']['input']>;
  /** Appeal ID that the pledge will be associated with */
  appealId?: InputMaybe<Scalars['ID']['input']>;
  /** Contact ID that the pledge will be associated with */
  contactId?: InputMaybe<Scalars['ID']['input']>;
  createdAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  expectedDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  overwrite?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PledgeStatusEnum>;
  updatedAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  updatedInDbAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

/** An edge in a connection. */
export type PledgeEdge = {
  __typename?: 'PledgeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Pledge>;
};

export enum PledgeFrequencyEnum {
  Annual = 'ANNUAL',
  Every_2Months = 'EVERY_2_MONTHS',
  Every_2Weeks = 'EVERY_2_WEEKS',
  Every_2Years = 'EVERY_2_YEARS',
  Every_4Months = 'EVERY_4_MONTHS',
  Every_6Months = 'EVERY_6_MONTHS',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
}

export enum PledgeSortEnum {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  ContactNameAsc = 'CONTACT_NAME_ASC',
  ContactNameDesc = 'CONTACT_NAME_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ExpectedDateAsc = 'EXPECTED_DATE_ASC',
  ExpectedDateDesc = 'EXPECTED_DATE_DESC',
}

export enum PledgeStatusEnum {
  NotReceived = 'NOT_RECEIVED',
  Processed = 'PROCESSED',
  ReceivedNotProcessed = 'RECEIVED_NOT_PROCESSED',
}

export type PledgeUpdateInput = {
  amount: Scalars['Float']['input'];
  amountCurrency?: InputMaybe<Scalars['String']['input']>;
  /** Appeal ID that the pledge will be associated with */
  appealId: Scalars['ID']['input'];
  /** Contact ID that the pledge will be associated with */
  contactId: Scalars['ID']['input'];
  createdAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  expectedDate: Scalars['ISO8601Date']['input'];
  id: Scalars['ID']['input'];
  overwrite?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PledgeStatusEnum>;
  updatedAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  updatedInDbAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

export type PrayerlettersAccount = {
  __typename?: 'PrayerlettersAccount';
  validToken: Scalars['Boolean']['output'];
};

export type PrayerlettersAccountInput = {
  accountListId: Scalars['ID']['input'];
};

export type Preference = {
  __typename?: 'Preference';
  contactsFilter?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  hourToSendNotifications?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  locale?: Maybe<Scalars['String']['output']>;
  localeDisplay?: Maybe<Scalars['String']['output']>;
  tabOrders?: Maybe<Scalars['String']['output']>;
  tasksFilter?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum PreferredContactMethodEnum {
  Email = 'EMAIL',
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  PhoneCall = 'PHONE_CALL',
  Sms = 'SMS',
  WeChat = 'WE_CHAT',
  WhatsApp = 'WHATS_APP',
}

export type Query = {
  __typename?: 'Query';
  _service: _Service;
  /** AccountList with a given ID */
  accountList: AccountList;
  accountListAnalytics: AccountListAnalytics;
  /** AccountListCoaches belonging to an AccountList */
  accountListCoaches: UserScopedToAccountListConnection;
  accountListDonorAccounts?: Maybe<Array<AccountListDonorAccount>>;
  /** AccountListInvite belonging to an AccountList */
  accountListInvites: AccountListInviteConnection;
  /** AccountListPledges belonging to an AccountList */
  accountListPledges: PledgeConnection;
  /** AccountListUsers belonging to an AccountList */
  accountListUsers: AccountListUserConnection;
  /** All current user AccountLists */
  accountLists: AccountListConnection;
  /** Address with a given ID */
  address: Address;
  /** All current user Addresses filterable by contactIds and validValues */
  addresses: AddressConnection;
  /** Announcements to display to the user via modal or banner */
  announcements: Array<Announcement>;
  /** Appeal with a given ID */
  appeal: Appeal;
  /** AppealContacts belonging to an Appeal */
  appealContacts: AppealContactConnection;
  /** All Appeals filterable by primary */
  appeals: AppealConnection;
  appointmentResults: Array<ReportsAppointmentResultsPeriod>;
  canUserExportData: CanUserExportData;
  /** CoachingAccountList with a given ID */
  coachingAccountList: CoachingAccountList;
  /** CoachingAccountListPledges belonging to an AccountList viewed by a coach */
  coachingAccountListPledges: PledgeConnection;
  /** All current user CoachingAccountLists */
  coachingAccountLists: CoachingAccountListConnection;
  coachingAnswerSets: Array<CoachingAnswerSet>;
  /** List of constants */
  constant: Constant;
  /** Contact with a given ID */
  contact: Contact;
  /** Contact duplicates belonging to an AccountList alphabetically by last name */
  contactDuplicates: ContactDuplicateConnection;
  /** Contacts belonging to an AccountList */
  contacts: ContactConnection;
  /** Return the most recent incomplete coaching answer set, creating a new one if necessary */
  currentCoachingAnswerSet: CoachingAnswerSet;
  designationAccounts: Array<DesignationAccountsGroup>;
  /** Donation with a given ID */
  donation: Donation;
  /** Donations belonging to an AccountList */
  donations: DonationConnection;
  entryHistories?: Maybe<Array<EntryHistoriesGroup>>;
  expectedMonthlyTotalReport: ExpectedMonthlyTotalReport;
  /** FinancialAccount with a given ID */
  financialAccount: FinancialAccount;
  financialAccountEntries: FinancialAccountEntriesResponse;
  financialAccountSummary: Array<Maybe<FinancialAccountSummaryResponse>>;
  /** FinancialAccounts belonging to an AccountList */
  financialAccounts: FinancialAccountConnection;
  fourteenMonthReport: FourteenMonthReport;
  getAccountListCoaches?: Maybe<Array<Maybe<AccountListCoaches>>>;
  getDesignationDisplayNames?: Maybe<Array<Maybe<DesignationWithDisplayName>>>;
  getOrganizations: AllOrganizations;
  googleAccountIntegrations: Array<Maybe<GoogleAccountIntegration>>;
  googleAccounts: Array<Maybe<GoogleAccountAttributes>>;
  mailchimpAccount?: Maybe<Array<Maybe<MailchimpAccount>>>;
  /** Find Notification Preferences by AccountList */
  notificationPreferences: NotificationPreferenceConnection;
  /** NotificationType translated into the locale of the current user */
  notificationTypes: Array<NotificationType>;
  organizationAdmins: Array<Maybe<AdminList>>;
  organizationInvites: Array<Maybe<Invite>>;
  /** list of organizations available to be connected with MPDX */
  organizations: Array<Organization>;
  partnerGivingAnalysisReport: PartnerGivingAnalysisReport;
  /** People belonging to an AccountList */
  people: PersonConnection;
  /** Person with a given ID */
  person: Person;
  /** Person duplicates belonging to an AccountList */
  personDuplicates: PersonDuplicateConnection;
  prayerlettersAccount?: Maybe<Array<Maybe<PrayerlettersAccount>>>;
  reportPledgeHistories?: Maybe<Array<Maybe<ReportsPledgeHistories>>>;
  /** Tasks activity completed by AccountList in the related periods */
  reportsActivityResults: ActivityResults;
  /** Donations received by AccountList in the related periods */
  reportsDonationHistories: DonationHistories;
  /** People associated with AccountList with an anniversary in the related periods */
  reportsPeopleWithAnniversaries: People;
  /** People associated with AccountList with a birthday in the related periods */
  reportsPeopleWithBirthdays: People;
  /** Tag Histories Report */
  reportsTagHistories: TagHistories;
  searchOrganizationsAccountLists: SearchOrganizationsAccountListsResponse;
  searchOrganizationsContacts: SearchOrganizationsContactsResponse;
  /** Task with a given ID */
  task: Task;
  taskAnalytics: TaskAnalytics;
  /** Tasks belonging to an AccountList */
  tasks: TaskConnection;
  /** Current User */
  user: User;
  /** Notifications to show the user */
  userNotifications: NotificationConnection;
  /** Key Value store for custom frontend data */
  userOptions: Array<Option>;
  /** OrganizationAccounts used to import data into MPDX */
  userOrganizationAccounts: Array<OrganizationAccount>;
};

export type QueryAccountListArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAccountListAnalyticsArgs = {
  accountListId: Scalars['ID']['input'];
  dateRange?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAccountListCoachesArgs = {
  accountListId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryAccountListDonorAccountsArgs = {
  accountListId: Scalars['ID']['input'];
  searchTerm: Scalars['String']['input'];
};

export type QueryAccountListInvitesArgs = {
  accountListId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  inviteType?: InputMaybe<InviteTypeEnum>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryAccountListPledgesArgs = {
  accountListId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  appealId?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contactId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PledgeSortEnum>;
  status?: InputMaybe<PledgeStatusEnum>;
};

export type QueryAccountListUsersArgs = {
  accountListId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryAccountListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryAddressArgs = {
  accountListId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type QueryAddressesArgs = {
  accountListId: Scalars['ID']['input'];
  addressableId?: InputMaybe<Array<Scalars['ID']['input']>>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  validValues?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAppealArgs = {
  accountListId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type QueryAppealContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  appealId: Scalars['ID']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pledgedToAppeal?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<ContactSortEnum>;
};

export type QueryAppealsArgs = {
  accountListId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAppointmentResultsArgs = {
  accountListId: Scalars['ID']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  range: Scalars['String']['input'];
};

export type QueryCanUserExportDataArgs = {
  accountListId: Scalars['ID']['input'];
};

export type QueryCoachingAccountListArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCoachingAccountListPledgesArgs = {
  accountListId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contactId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PledgeSortEnum>;
};

export type QueryCoachingAccountListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryCoachingAnswerSetsArgs = {
  accountListId: Scalars['ID']['input'];
  completed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContactArgs = {
  accountListId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type QueryContactDuplicatesArgs = {
  accountListId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contactIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ignore?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryContactsArgs = {
  accountListId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contactsFilter?: InputMaybe<ContactFilterSetInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<ContactSortEnum>;
};

export type QueryCurrentCoachingAnswerSetArgs = {
  accountListId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type QueryDesignationAccountsArgs = {
  accountListId: Scalars['ID']['input'];
};

export type QueryDonationArgs = {
  accountListId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type QueryDonationsArgs = {
  accountListId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  appealId?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  designationAccountId?: InputMaybe<Array<Scalars['ID']['input']>>;
  donationDate?: InputMaybe<DateRangeInput>;
  donorAccountId?: InputMaybe<Array<Scalars['ID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryEntryHistoriesArgs = {
  accountListId: Scalars['ID']['input'];
  financialAccountIds: Array<Scalars['ID']['input']>;
};

export type QueryExpectedMonthlyTotalReportArgs = {
  accountListId: Scalars['ID']['input'];
  designationAccountId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QueryFinancialAccountArgs = {
  accountListId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type QueryFinancialAccountEntriesArgs = {
  input: FinancialAccountEntriesInput;
};

export type QueryFinancialAccountSummaryArgs = {
  input: FinancialAccountSummaryInput;
};

export type QueryFinancialAccountsArgs = {
  accountListId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  designationAccountId?: InputMaybe<Array<Scalars['ID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFourteenMonthReportArgs = {
  accountListId: Scalars['ID']['input'];
  currencyType: FourteenMonthReportCurrencyType;
  designationAccountId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QueryGetDesignationDisplayNamesArgs = {
  accountListId: Scalars['ID']['input'];
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

export type QueryGoogleAccountIntegrationsArgs = {
  input: GoogleAccountIntegrationsInput;
};

export type QueryMailchimpAccountArgs = {
  input: MailchimpAccountInput;
};

export type QueryNotificationPreferencesArgs = {
  accountListId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryOrganizationAdminsArgs = {
  input: OrganizationAdminsInput;
};

export type QueryOrganizationInvitesArgs = {
  input: OrganizationInvitesInput;
};

export type QueryPartnerGivingAnalysisReportArgs = {
  input: PartnerGivingAnalysisReportInput;
};

export type QueryPeopleArgs = {
  accountListId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  peopleFilter?: InputMaybe<PersonFilterSetInput>;
};

export type QueryPersonArgs = {
  accountListId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type QueryPersonDuplicatesArgs = {
  accountListId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ignore?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryPrayerlettersAccountArgs = {
  input: PrayerlettersAccountInput;
};

export type QueryReportPledgeHistoriesArgs = {
  accountListId: Scalars['ID']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  range?: InputMaybe<Scalars['String']['input']>;
};

export type QueryReportsActivityResultsArgs = {
  accountListId: Scalars['ID']['input'];
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  range?: InputMaybe<Scalars['String']['input']>;
};

export type QueryReportsDonationHistoriesArgs = {
  accountListId: Scalars['ID']['input'];
  designationAccountId?: InputMaybe<Array<Scalars['ID']['input']>>;
  donorAccountId?: InputMaybe<Array<Scalars['ID']['input']>>;
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  range?: InputMaybe<Scalars['String']['input']>;
};

export type QueryReportsPeopleWithAnniversariesArgs = {
  accountListId: Scalars['ID']['input'];
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  range?: InputMaybe<Scalars['String']['input']>;
};

export type QueryReportsPeopleWithBirthdaysArgs = {
  accountListId: Scalars['ID']['input'];
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  range?: InputMaybe<Scalars['String']['input']>;
};

export type QueryReportsTagHistoriesArgs = {
  accountListId: Scalars['ID']['input'];
  association?: InputMaybe<ReportsTagHistoriesAssociationEnum>;
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  range?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySearchOrganizationsAccountListsArgs = {
  input: SearchOrganizationsAccountListsInput;
};

export type QuerySearchOrganizationsContactsArgs = {
  input: SearchOrganizationsContactsInput;
};

export type QueryTaskArgs = {
  accountListId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type QueryTaskAnalyticsArgs = {
  accountListId: Scalars['ID']['input'];
};

export type QueryTasksArgs = {
  accountListId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<TaskSortEnum>;
  tasksFilter?: InputMaybe<TaskFilterSetInput>;
};

export type QueryUserNotificationsArgs = {
  accountListId?: InputMaybe<Scalars['ID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryUserOptionsArgs = {
  key?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RadioFilter = Filter & {
  __typename?: 'RadioFilter';
  defaultSelection?: Maybe<Scalars['String']['output']>;
  filterKey: Scalars['String']['output'];
  options?: Maybe<Array<FilterOption>>;
  title: Scalars['String']['output'];
};

export enum ReasonEnum {
  SameDonorAccountNumber = 'SAME_DONOR_ACCOUNT_NUMBER',
  SimilarEmailAddresses = 'SIMILAR_EMAIL_ADDRESSES',
  SimilarNames = 'SIMILAR_NAMES',
  SimilarPhoneNumbers = 'SIMILAR_PHONE_NUMBERS',
}

export type Referral = {
  __typename?: 'Referral';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  referredBy: Contact;
  referredTo: Contact;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Referral. */
export type ReferralConnection = {
  __typename?: 'ReferralConnection';
  /** A list of edges. */
  edges: Array<ReferralEdge>;
  /** A list of nodes. */
  nodes: Array<Referral>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReferralEdge = {
  __typename?: 'ReferralEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Referral>;
};

export type ReportContactFilterSetInput = {
  /** Filter by Address No Longer Valid; Accepts values 'true', or 'false' */
  addressHistoric?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter contacts with addresses located within the lat lng bounds. Hash should contain a ne and sw lat lng pair. */
  addressLatLng?: InputMaybe<Scalars['String']['input']>;
  /** Filter where address value is false; accepts "false" */
  addressValid?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by alma mater; Accepts multiple parameters, with value 'none', or an alma mater name */
  almaMater?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter where contact people have anniversary on date between provided date range */
  anniversary?: InputMaybe<DateRangeInput>;
  /**
   * When filtering by tags this specifies if the filter should match tasks with
   * any of the filtered tags if "true" or only tasks with all of the filtered tags if "false"
   */
  anyTags?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by Appeal; Accepts multiple parameters, with value 'no_appeals', or an appeal ID */
  appeal?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Filter by Pledge Status. One of excluded, asked, not_received,
   * received_not_processed, or processed. Must be used with appeal filter.
   */
  appealStatus?: InputMaybe<Scalars['String']['input']>;
  /** Filter where contact people have birthday on date between provided date range */
  birthday?: InputMaybe<DateRangeInput>;
  /** Filter by Church; Accepts multiple parameters, with value 'none', or a church name */
  church?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by City; Accepts multiple parameters, with value 'none', or a city name */
  city?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by where contact has address; Accepts values 'Yes', or 'No' */
  contactInfoAddr?: InputMaybe<Scalars['String']['input']>;
  /** Filter where contact people has an email; Accepts values 'Yes', or 'No' */
  contactInfoEmail?: InputMaybe<Scalars['String']['input']>;
  /** Filter where contact people has a Facebook Profile; Accepts values 'Yes', or 'No' */
  contactInfoFacebook?: InputMaybe<Scalars['String']['input']>;
  /** Filter where contact people has a mobile phone; Accepts values 'Yes', or 'No' */
  contactInfoMobile?: InputMaybe<Scalars['String']['input']>;
  /** Filter where contact people has a phone; Accepts values 'Yes', or 'No' */
  contactInfoPhone?: InputMaybe<Scalars['String']['input']>;
  /** Filter where contact people has a work phone; Accepts values 'Yes', or 'No' */
  contactInfoWorkPhone?: InputMaybe<Scalars['String']['input']>;
  /** Filter by Type; Accepts multiple parameters, with values 'person', and 'company' */
  contactType?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Country; Accepts multiple parameters, with values 'none', or a country */
  country?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Created At date between a provided date range */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Filter by Designation Account; accepts multiple parameters, separated by comma */
  designationAccountId?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by Gift Options; Accepts multiple parameters, with values 'none', 'one', 'first', and 'last' */
  donation?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Exact Gift Amount; Accepts multiple parameters, with values like '9.99' */
  donationAmount?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Gift Amount Range; Accepts object with max and min keys with decimal values { "max": "9.99", "min": "0.99" } */
  donationAmountRange?: InputMaybe<NumericRangeInput>;
  /** Filter by Gift Date; Accepts date range with text value like 'MM/DD/YYYY - MM/DD/YYYY' */
  donationDate?: InputMaybe<DateRangeInput>;
  donationPeriodAverage?: InputMaybe<NumericRangeInput>;
  donationPeriodCount?: InputMaybe<NumericRangeInput>;
  donationPeriodPercentRank?: InputMaybe<NumericRangeInput>;
  donationPeriodSum?: InputMaybe<NumericRangeInput>;
  /** Filter contacts that have any of the tags included in this filter; comma separated value for multiple */
  excludeTags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter where contact gave more than they pledged within the provided date range */
  gaveMoreThanPledgedRange?: InputMaybe<DateRangeInput>;
  /** Filter contacts with ids included in this filter; comma separated value for multiple */
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter contacts by the date at which the contact started being late on payments */
  lateAt?: InputMaybe<DateRangeInput>;
  /** Filter by Likely To Give; Accepts multiple parameters, with values 'none', 'Least Likely', 'Likely', and 'Most Likely' */
  likely?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Language; Accepts multiple parameters, */
  locale?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Metro Area; Accepts multiple parameters, with values 'none', or a metro area name */
  metroArea?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter where contact name starts with the provided value */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Filter by Newsletter Recipients; Accepts values 'none', 'all', 'address', 'email', and 'both' */
  newsletter?: InputMaybe<ContactFilterNewsletterEnum>;
  /** Filter by Next Ask date within the provided date range */
  nextAsk?: InputMaybe<DateRangeInput>;
  /** Filter where contact has asked specifically not to receive appeals; accepts "true" */
  noAppeals?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Filter contacts with note contents; accepts hash with wildcard_note_search
   * parameter { "wildcard_note_search": "value to search by" }
   */
  notes?: InputMaybe<ContactFilterNotesInput>;
  /** Filter where at least one contact person has opted out from email; Accepts values 'Yes', or 'No' */
  optOut?: InputMaybe<Scalars['String']['input']>;
  /** filter where contact's account list has a designation account belonging to an organization; Accepts IDs separated by comma */
  organizationId?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter contacts by pledge status. completed, outstanding, pending */
  pledge?: InputMaybe<Scalars['String']['input']>;
  /** Filter by Pledge Amount; Accepts multiple parameters, with values like '100.0' */
  pledgeAmount?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter where Pledge Amount increased in the provided date range */
  pledgeAmountIncreasedRange?: InputMaybe<DateRangeInput>;
  /** Filter by Pledge Currency; Accepts multiple parameters, with values like 'USD' */
  pledgeCurrency?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Filter by Pledge Frequency; Accepts multiple parameters, with numeric values
   * like '0.23076923076923' (Weekly), '0.46153846153846' (Every 2 Weeks), '1.0'
   * (Monthly), '2.0' (Every 2 Months), '3.0', '4.0', '6.0', '12.0' (Yearly), and
   * '24.0' (Every 2 Years)
   */
  pledgeFrequency?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Filter by Late By; Accepts values '', '0_30' (Less than 30 days late), '30_60'
   * (More than 30 days late), '60_90' (More than 60 days late), or '90' (More than 90 days late)
   */
  pledgeLateBy?: InputMaybe<Scalars['String']['input']>;
  /** Filter by Pledge Received; Accepts values 'true', or 'false' */
  pledgeReceived?: InputMaybe<ContactFilterPledgeReceivedEnum>;
  /** Filter contacts with addresses in particular state; accepts values 'primary', 'active', 'inactive', or 'null' */
  primaryAddress?: InputMaybe<Scalars['String']['input']>;
  /** Filter by Referrer; Accepts multiple parameters, with values 'none', 'any', or a Contact ID */
  referrer?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Referrer ID; Accepts Comma separated list of Contact IDs */
  referrerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by Region; Accepts multiple parameters, with values 'none', or a region name */
  region?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Action; Accepts multiple parameters, with values 'none', or an activity type like 'Call' */
  relatedTaskAction?: InputMaybe<Array<ContactFilterActivityTypeEnum>>;
  reverseAlmaMater?: InputMaybe<Scalars['Boolean']['input']>;
  reverseAppeal?: InputMaybe<Scalars['Boolean']['input']>;
  reverseChurch?: InputMaybe<Scalars['Boolean']['input']>;
  reverseCity?: InputMaybe<Scalars['Boolean']['input']>;
  reverseContactType?: InputMaybe<Scalars['Boolean']['input']>;
  reverseCountry?: InputMaybe<Scalars['Boolean']['input']>;
  reverseDesignationAccountId?: InputMaybe<Scalars['Boolean']['input']>;
  reverseDonation?: InputMaybe<Scalars['Boolean']['input']>;
  reverseDonationAmount?: InputMaybe<Scalars['Boolean']['input']>;
  reverseDonationPeriodAverage?: InputMaybe<Scalars['Boolean']['input']>;
  reverseDonationPeriodCount?: InputMaybe<Scalars['Boolean']['input']>;
  reverseDonationPeriodPercentRank?: InputMaybe<Scalars['Boolean']['input']>;
  reverseDonationPeriodSum?: InputMaybe<Scalars['Boolean']['input']>;
  reverseIds?: InputMaybe<Scalars['Boolean']['input']>;
  reverseLikely?: InputMaybe<Scalars['Boolean']['input']>;
  reverseLocale?: InputMaybe<Scalars['Boolean']['input']>;
  reverseMetroArea?: InputMaybe<Scalars['Boolean']['input']>;
  reversePledgeAmount?: InputMaybe<Scalars['Boolean']['input']>;
  reversePledgeCurrency?: InputMaybe<Scalars['Boolean']['input']>;
  reversePledgeFrequency?: InputMaybe<Scalars['Boolean']['input']>;
  reverseReferrer?: InputMaybe<Scalars['Boolean']['input']>;
  reverseRegion?: InputMaybe<Scalars['Boolean']['input']>;
  reverseRelatedTaskAction?: InputMaybe<Scalars['Boolean']['input']>;
  reverseSource?: InputMaybe<Scalars['Boolean']['input']>;
  reverseState?: InputMaybe<Scalars['Boolean']['input']>;
  reverseStatus?: InputMaybe<Scalars['Boolean']['input']>;
  reverseTags?: InputMaybe<Scalars['Boolean']['input']>;
  reverseTimezone?: InputMaybe<Scalars['Boolean']['input']>;
  reverseUserIds?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by Source; Accepts multiple parameters */
  source?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by starred, is either selected or unselected */
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter where contact started giving in the provided date range */
  startedGivingRange?: InputMaybe<DateRangeInput>;
  /** Filter by State; Accepts multiple parameters, with values 'none', or a state */
  state?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Filter by Status; Accepts multiple parameters, with values 'active', 'hidden',
   * 'null', 'Never Contacted', 'Ask in Future', 'Cultivate Relationship', 'Contact
   * for Appointment', 'Appointment Scheduled', 'Call for Decision', 'Partner -
   * Financial', 'Partner - Special', 'Partner - Pray', 'Not Interested',
   * 'Unresponsive', 'Never Ask', 'Research Abandoned', and 'Expired Referral'
   */
  status?: InputMaybe<Array<ContactFilterStatusEnum>>;
  /** Filter by Status Valid; Accepts values 'true', or 'false' */
  statusValid?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter where contact stopped giving in the provided date range */
  stoppedGivingRange?: InputMaybe<DateRangeInput>;
  /** Filter by Tags; Accepts multiple parameters, with text values separated by comma */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Due Date; Accepts date range with text value like 'MM/DD/YYYY - MM/DD/YYYY' */
  taskDueDate?: InputMaybe<DateRangeInput>;
  /** Filter where contact's tasks have completed set to true; accepts "true" */
  tasksAllCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by Timezone; Accepts multiple parameters */
  timezone?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter contacts that were updated in this date range */
  updatedAt?: InputMaybe<DateTimeRangeInput>;
  /** Filter contacts with user_ids included in this filter; comma separated value for multiple */
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter contacts where subject contains this filter */
  wildcardSearch?: InputMaybe<Scalars['String']['input']>;
};

export type ReportsAppointmentResultsPeriod = {
  __typename?: 'ReportsAppointmentResultsPeriod';
  appointmentsScheduled: Scalars['Int']['output'];
  endDate: Scalars['ISO8601DateTime']['output'];
  groupAppointments: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  individualAppointments: Scalars['Int']['output'];
  monthlyDecrease: Scalars['Float']['output'];
  monthlyIncrease: Scalars['Float']['output'];
  newMonthlyPartners: Scalars['Int']['output'];
  newSpecialPledges: Scalars['Int']['output'];
  pledgeIncrease: Scalars['Float']['output'];
  specialGifts: Scalars['Float']['output'];
  startDate: Scalars['ISO8601DateTime']['output'];
  type: Scalars['String']['output'];
  weeklyIndividualAppointmentGoal: Scalars['Int']['output'];
};

export type ReportsPledgeHistories = {
  __typename?: 'ReportsPledgeHistories';
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  pledged?: Maybe<Scalars['Float']['output']>;
  received?: Maybe<Scalars['Float']['output']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedInDbAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export enum ReportsTagHistoriesAssociationEnum {
  Contacts = 'CONTACTS',
  Tasks = 'TASKS',
}

export type Result = {
  __typename?: 'Result';
  resultOptions?: Maybe<Array<ResultOption>>;
  tags?: Maybe<Array<IdValue>>;
};

export enum ResultEnum {
  Attempted = 'ATTEMPTED',
  AttemptedLeftMessage = 'ATTEMPTED_LEFT_MESSAGE',
  Completed = 'COMPLETED',
  Done = 'DONE',
  /** special type when filtered by will return any task with no result */
  None = 'NONE',
  Received = 'RECEIVED',
}

export type ResultOption = {
  __typename?: 'ResultOption';
  dbResult?: Maybe<Array<TaskResultPair>>;
  name: DisplayResultEnum;
  suggestedContactStatus?: Maybe<StatusEnum>;
  suggestedNextActions?: Maybe<Array<ActivityTypeEnum>>;
};

export type SaveCoachingAnswerInput = {
  answerId?: InputMaybe<Scalars['String']['input']>;
  answerSetId: Scalars['String']['input'];
  questionId: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

export type SearchOrganizationsAccountListsInput = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  search: Scalars['String']['input'];
};

export type SearchOrganizationsAccountListsResponse = {
  __typename?: 'SearchOrganizationsAccountListsResponse';
  accountLists: Array<Maybe<OrganizationsAccountList>>;
  pagination: Pagination;
};

export type SearchOrganizationsContactsInput = {
  organizationId: Scalars['ID']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  search: Scalars['String']['input'];
};

export type SearchOrganizationsContactsResponse = {
  __typename?: 'SearchOrganizationsContactsResponse';
  contacts: Array<Maybe<OrganizationsContact>>;
  pagination: Pagination;
};

export enum SendNewsletterEnum {
  Both = 'BOTH',
  Email = 'EMAIL',
  None = 'NONE',
  Physical = 'PHYSICAL',
}

export type SendToChalklineInput = {
  accountListId: Scalars['ID']['input'];
};

export type SetActiveDesignationAccountInput = {
  accountListId: Scalars['ID']['input'];
  active: Scalars['Boolean']['input'];
  designationAccountId: Scalars['ID']['input'];
};

export type SetActiveFinancialAccountInput = {
  accountListId: Scalars['ID']['input'];
  active: Scalars['Boolean']['input'];
  financialAccountId: Scalars['ID']['input'];
};

export type SetActiveFinancialAccountRest = {
  __typename?: 'SetActiveFinancialAccountRest';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export enum SortDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export enum StatusEnum {
  AppointmentScheduled = 'APPOINTMENT_SCHEDULED',
  AskInFuture = 'ASK_IN_FUTURE',
  CallForDecision = 'CALL_FOR_DECISION',
  ContactForAppointment = 'CONTACT_FOR_APPOINTMENT',
  CultivateRelationship = 'CULTIVATE_RELATIONSHIP',
  ExpiredReferral = 'EXPIRED_REFERRAL',
  NeverAsk = 'NEVER_ASK',
  NeverContacted = 'NEVER_CONTACTED',
  NotInterested = 'NOT_INTERESTED',
  PartnerFinancial = 'PARTNER_FINANCIAL',
  PartnerPray = 'PARTNER_PRAY',
  PartnerSpecial = 'PARTNER_SPECIAL',
  ResearchAbandoned = 'RESEARCH_ABANDONED',
  ResearchContactInfo = 'RESEARCH_CONTACT_INFO',
  Unresponsive = 'UNRESPONSIVE',
}

export type StatusesConstant = {
  __typename?: 'StatusesConstant';
  id: StatusEnum;
  value: Scalars['String']['output'];
};

export enum StyleEnum {
  /** announcement should be colored as dangerous */
  Danger = 'danger',
  /** announcement should not have coloring */
  Default = 'default',
  /** announcement should be colored as an informational */
  Info = 'info',
  /** announcement should be colored as successful */
  Success = 'success',
  /** announcement should be colored as a warning */
  Warning = 'warning',
}

export type SyncGoogleAccountInput = {
  googleAccountId: Scalars['ID']['input'];
  googleIntegrationId: Scalars['ID']['input'];
  integrationName: Scalars['String']['input'];
};

export type SyncMailchimpAccountInput = {
  accountListId: Scalars['ID']['input'];
};

export type SyncPrayerlettersAccountInput = {
  accountListId: Scalars['ID']['input'];
};

export type Tag = {
  __typename?: 'Tag';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TagHistories = {
  __typename?: 'TagHistories';
  periods: Array<TagHistoriesPeriod>;
};

export type TagHistoriesPeriod = {
  __typename?: 'TagHistoriesPeriod';
  endDate: Scalars['ISO8601Date']['output'];
  startDate: Scalars['ISO8601Date']['output'];
  tags: Array<Tag>;
};

export type Task = {
  __typename?: 'Task';
  activityType?: Maybe<ActivityTypeEnum>;
  comments: CommentConnection;
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  contactIds: Array<Scalars['String']['output']>;
  contacts: ContactConnection;
  createdAt: Scalars['ISO8601DateTime']['output'];
  displayResult?: Maybe<DisplayResultEnum>;
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  nextAction?: Maybe<ActivityTypeEnum>;
  notificationTimeBefore?: Maybe<Scalars['Int']['output']>;
  notificationTimeUnit?: Maybe<NotificationTimeUnitEnum>;
  notificationType?: Maybe<NotificationTypeEnum>;
  result?: Maybe<ResultEnum>;
  /** Indicates if a user has starred the task */
  starred: Scalars['Boolean']['output'];
  startAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  subject: Scalars['String']['output'];
  tagList: Array<Scalars['String']['output']>;
  taskPhase?: Maybe<PhaseEnum>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<UserScopedToAccountList>;
};

export type TaskCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type TaskContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type TaskAnalytics = {
  __typename?: 'TaskAnalytics';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  lastElectronicNewsletterCompletedAt?: Maybe<
    Scalars['ISO8601DateTime']['output']
  >;
  lastPhysicalNewsletterCompletedAt?: Maybe<
    Scalars['ISO8601DateTime']['output']
  >;
  tasksOverdueOrDueTodayCounts: Array<OverdueOrDueTodayTaskAnalytic>;
  totalTasksDueCount: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  updatedInDbAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskCommentCreateInput = {
  body: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of TaskCommentCreateMutation */
export type TaskCommentCreateMutationInput = {
  /** AccountList ID that the comment is associated with */
  accountListId: Scalars['ID']['input'];
  /** attributes to create */
  attributes: TaskCommentCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Task ID that the comment is associated with */
  taskId: Scalars['ID']['input'];
};

/** Autogenerated return type of TaskCommentCreateMutation */
export type TaskCommentCreateMutationPayload = {
  __typename?: 'TaskCommentCreateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  comment: Comment;
};

/** The connection type for Task. */
export type TaskConnection = {
  __typename?: 'TaskConnection';
  /** A list of edges. */
  edges: Array<TaskEdge>;
  /** A list of nodes. */
  nodes: Array<Task>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

export type TaskCreateInput = {
  activityType?: InputMaybe<ActivityTypeEnum>;
  /** Comment to add to new task */
  comment?: InputMaybe<Scalars['String']['input']>;
  completedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  contactIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  displayResult?: InputMaybe<DisplayResultEnum>;
  location?: InputMaybe<Scalars['String']['input']>;
  nextAction?: InputMaybe<ActivityTypeEnum>;
  notificationTimeBefore?: InputMaybe<Scalars['Int']['input']>;
  notificationTimeUnit?: InputMaybe<NotificationTimeUnitEnum>;
  notificationType?: InputMaybe<NotificationTypeEnum>;
  result?: InputMaybe<ResultEnum>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  startAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  subject: Scalars['String']['input'];
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of TaskCreateMutation */
export type TaskCreateMutationInput = {
  /** AccountList ID that the task is associated with */
  accountListId: Scalars['ID']['input'];
  /** attributes to create */
  attributes: TaskCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of TaskCreateMutation */
export type TaskCreateMutationPayload = {
  __typename?: 'TaskCreateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  task: Task;
};

/** Autogenerated input type of TaskDeleteMutation */
export type TaskDeleteMutationInput = {
  /** AccountList ID that the task is associated with */
  accountListId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of task to delete */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TaskDeleteMutation */
export type TaskDeleteMutationPayload = {
  __typename?: 'TaskDeleteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

/** An edge in a connection. */
export type TaskEdge = {
  __typename?: 'TaskEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Task>;
};

export type TaskFilterSetInput = {
  /** Filter by Action; Accepts multiple parameters, with values "Call", "Appointment", "Email", "Text Message", "Facebook Message", "Letter", "Newsletter", "Pre Call Letter", "Reminder Letter", "Support Letter", "Thank", "To Do", "Talk to In Person", or "Prayer Request" */
  activityType?: InputMaybe<Array<ActivityTypeEnum>>;
  /** When filtering by tags this specifies if the filter should match tasks with any of the filtered tags if "true" or only tasks with all of the filtered tags if "false" */
  anyTags?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by Completed; Accepts values "true", or "false" */
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter Tasks by completed at date given a date range */
  completedAt?: InputMaybe<DateTimeRangeInput>;
  /** Filter by Appeal; Accepts multiple parameters, with value 'no_appeals', or an appeal ID */
  contactAppeal?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Church; Accepts multiple parameters, with value 'none', or a church name */
  contactChurch?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by City; Accepts multiple parameters, with value 'none', or a city name */
  contactCity?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Country; Accepts multiple parameters, with values 'none', or a country */
  contactCountry?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Designation Account; accepts multiple parameters, separated by comma */
  contactDesignationAccountId?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by Contact IDs; Accepts multiple parameters, with Contact IDs */
  contactIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter where contact has address; Accepts values 'Yes', or 'No' */
  contactInfoAddr?: InputMaybe<Scalars['String']['input']>;
  /** Filter where contact people have email addresses; Accepts values 'Yes', or 'No' */
  contactInfoEmail?: InputMaybe<Scalars['String']['input']>;
  /** Filter where contact people have mobile phones; Accepts values 'Yes', or 'No' */
  contactInfoMobile?: InputMaybe<Scalars['String']['input']>;
  /** Filter where contact people have home phones; Accepts values 'Yes', or 'No' */
  contactInfoPhone?: InputMaybe<Scalars['String']['input']>;
  /** Filter where contact people have work phones; Accepts values 'Yes', or 'No' */
  contactInfoWorkPhone?: InputMaybe<Scalars['String']['input']>;
  /** Filter by Likely To Give; Accepts multiple parameters, with values 'none', 'Least Likely', 'Likely', and 'Most Likely' */
  contactLikely?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Metro Area; Accepts multiple parameters, with values 'none', or a metro area name */
  contactMetroArea?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Newsletter Recipients; Accepts values 'none', 'all', 'address', 'email', and 'both' */
  contactNewsletter?: InputMaybe<Scalars['String']['input']>;
  /** Filter by Pledge Frequency; Accepts multiple parameters, with numeric values like '0.23076923076923' (Weekly), '0.46153846153846' (Every 2 Weeks), '1.0' (Monthly), '2.0' (Every 2 Months), '3.0', '4.0', '6.0', '12.0' (Yearly), and '24.0' (Every 2 Years) */
  contactPledgeFrequency?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Referrer; Accepts multiple parameters, with values 'none', 'any', or a Contact ID */
  contactReferrer?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Region; Accepts multiple parameters, with values 'none', or a region name */
  contactRegion?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by State; Accepts multiple parameters, with values 'none', or a state */
  contactState?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Status; Accepts multiple parameters, with values 'active', 'hidden', 'null', 'Never Contacted', 'Ask in Future', 'Cultivate Relationship', 'Contact for Appointment', 'Appointment Scheduled', 'Call for Decision', 'Partner - Financial', 'Partner - Special', 'Partner - Pray', 'Not Interested', 'Unresponsive', 'Never Ask', 'Research Abandoned', and 'Expired Referral' */
  contactStatus?: InputMaybe<Array<ContactFilterStatusEnum>>;
  /** Filter by Timezone; Accepts multiple parameters */
  contactTimezone?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by Type; Accepts multiple parameters, with values 'person', and 'company' */
  contactType?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<DateRangeInput>;
  /** Filter by Date Range; Accepts values "last_month", "last_year", "last_two_years", "last_week", "overdue", "today", "tomorrow", "future", "upcoming" and "no_date" */
  dateRange?: InputMaybe<Scalars['String']['input']>;
  /** Filter tasks that have any of the tags included in this filter; comma separated value for multiple */
  excludeTags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter tasks with ids included in this filter; comma separated value for multiple */
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by Next Action; Accepts multiple parameters */
  nextAction?: InputMaybe<Array<ActivityTypeEnum>>;
  /** Filter by overdue; Accepts values "true", or "false" */
  overdue?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by Result; Accepts multiple parameters */
  result?: InputMaybe<Array<ResultEnum>>;
  reverseActivityType?: InputMaybe<Scalars['Boolean']['input']>;
  reverseContactAppeal?: InputMaybe<Scalars['Boolean']['input']>;
  reverseContactChurch?: InputMaybe<Scalars['Boolean']['input']>;
  reverseContactCity?: InputMaybe<Scalars['Boolean']['input']>;
  reverseContactCountry?: InputMaybe<Scalars['Boolean']['input']>;
  reverseContactDesignationAccountId?: InputMaybe<Scalars['Boolean']['input']>;
  reverseContactIds?: InputMaybe<Scalars['Boolean']['input']>;
  reverseContactLikely?: InputMaybe<Scalars['Boolean']['input']>;
  reverseContactMetroArea?: InputMaybe<Scalars['Boolean']['input']>;
  reverseContactPledgeFrequency?: InputMaybe<Scalars['Boolean']['input']>;
  reverseContactReferrer?: InputMaybe<Scalars['Boolean']['input']>;
  reverseContactRegion?: InputMaybe<Scalars['Boolean']['input']>;
  reverseContactState?: InputMaybe<Scalars['Boolean']['input']>;
  reverseContactStatus?: InputMaybe<Scalars['Boolean']['input']>;
  reverseContactTimezone?: InputMaybe<Scalars['Boolean']['input']>;
  reverseContactType?: InputMaybe<Scalars['Boolean']['input']>;
  reverseNextAction?: InputMaybe<Scalars['Boolean']['input']>;
  reverseResult?: InputMaybe<Scalars['Boolean']['input']>;
  reverseTags?: InputMaybe<Scalars['Boolean']['input']>;
  reverseUserIds?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by starred; Accepts values "true", or "false" */
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter Tasks by start at date given a date range */
  startAt?: InputMaybe<DateTimeRangeInput>;
  /** Filter by Tags; Accepts multiple parameters, with text values separated by comma */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter tasks that were updated in this date range */
  updatedAt?: InputMaybe<DateTimeRangeInput>;
  /** Filter tasks with user_ids included in this filter; comma separated value for multiple */
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter tasks where subject contains this filter */
  wildcardSearch?: InputMaybe<Scalars['String']['input']>;
};

export type TaskResultPair = {
  __typename?: 'TaskResultPair';
  result?: Maybe<ResultEnum>;
  task?: Maybe<ActivityTypeEnum>;
};

export enum TaskSortEnum {
  /** sort by created_at ASC */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** sort by created_at DESC */
  CreatedAtDesc = 'CREATED_AT_DESC',
  /** most recently overdue first, then completed at descending, then start at ascending, then tasks with no due date */
  DefaultSortParam = 'DEFAULT_SORT_PARAM',
  /** sort by start_at ASC */
  StartAtAsc = 'START_AT_ASC',
  /** sort by start_at DESC */
  StartAtDesc = 'START_AT_DESC',
}

export type TaskUpdateInput = {
  activityType?: InputMaybe<ActivityTypeEnum>;
  completedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  contactIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  displayResult?: InputMaybe<DisplayResultEnum>;
  id: Scalars['ID']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  nextAction?: InputMaybe<ActivityTypeEnum>;
  notificationTimeBefore?: InputMaybe<Scalars['Int']['input']>;
  notificationTimeUnit?: InputMaybe<NotificationTimeUnitEnum>;
  notificationType?: InputMaybe<NotificationTypeEnum>;
  result?: InputMaybe<ResultEnum>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  startAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of TaskUpdateMutation */
export type TaskUpdateMutationInput = {
  /** AccountList ID that the task is associated with */
  accountListId: Scalars['ID']['input'];
  /** attributes to update */
  attributes: TaskUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of TaskUpdateMutation */
export type TaskUpdateMutationPayload = {
  __typename?: 'TaskUpdateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  task: Task;
};

/** Autogenerated input type of TasksCreateMutation */
export type TasksCreateMutationInput = {
  /** AccountList ID that the tasks are associated with */
  accountListId: Scalars['ID']['input'];
  /** Attributes of created tasks */
  attributes: TaskCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of TasksCreateMutation */
export type TasksCreateMutationPayload = {
  __typename?: 'TasksCreateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  tasks: Array<Task>;
};

/** Autogenerated input type of TasksDeleteMutation */
export type TasksDeleteMutationInput = {
  /** AccountList ID that the task is associated with */
  accountListId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** IDs of task to delete */
  ids: Array<Scalars['String']['input']>;
};

/** Autogenerated return type of TasksDeleteMutation */
export type TasksDeleteMutationPayload = {
  __typename?: 'TasksDeleteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  ids: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of TasksUpdateMutation */
export type TasksUpdateMutationInput = {
  /** AccountList ID that the task is associated with */
  accountListId: Scalars['ID']['input'];
  /** attributes to update */
  attributes: Array<TaskUpdateInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of TasksUpdateMutation */
export type TasksUpdateMutationPayload = {
  __typename?: 'TasksUpdateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  tasks: Array<Task>;
};

export type TextFilter = Filter & {
  __typename?: 'TextFilter';
  filterKey: Scalars['String']['output'];
  options?: Maybe<Array<FilterOption>>;
  title: Scalars['String']['output'];
};

export type TextMessageAccountListAnalytics = {
  __typename?: 'TextMessageAccountListAnalytics';
  received: Scalars['Int']['output'];
  sent: Scalars['Int']['output'];
};

export type Time = {
  __typename?: 'Time';
  key?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Total = {
  __typename?: 'Total';
  /** donation total without currency conversion */
  amount: Scalars['Float']['output'];
  /** donation total converted half-way through period */
  convertedAmount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
};

export type TwitterAccount = {
  __typename?: 'TwitterAccount';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  screenName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for TwitterAccount. */
export type TwitterAccountConnection = {
  __typename?: 'TwitterAccountConnection';
  /** A list of edges. */
  edges: Array<TwitterAccountEdge>;
  /** A list of nodes. */
  nodes: Array<TwitterAccount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TwitterAccountEdge = {
  __typename?: 'TwitterAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<TwitterAccount>;
};

export enum TypeEnum {
  Contact = 'Contact',
  Person = 'Person',
}

export type UpdateGoogleIntegrationInput = {
  googleAccountId: Scalars['ID']['input'];
  googleIntegration: GoogleAccountIntegrationInput;
  googleIntegrationId: Scalars['ID']['input'];
};

export type UpdateMailchimpAccountInput = {
  accountListId: Scalars['ID']['input'];
  mailchimpAccount: UpdateMailchimpAccountInputAccount;
  mailchimpAccountId: Scalars['ID']['input'];
};

export type UpdateMailchimpAccountInputAccount = {
  autoLogCampaigns: Scalars['Boolean']['input'];
  primaryListId?: InputMaybe<Scalars['ID']['input']>;
};

export type User = {
  __typename?: 'User';
  admin: Scalars['Boolean']['output'];
  administrativeOrganizations: OrganizationConnection;
  almaMater?: Maybe<Scalars['String']['output']>;
  anniversaryDay?: Maybe<Scalars['Int']['output']>;
  anniversaryMonth?: Maybe<Scalars['Int']['output']>;
  anniversaryYear?: Maybe<Scalars['Int']['output']>;
  avatar: Scalars['String']['output'];
  birthdayDay?: Maybe<Scalars['Int']['output']>;
  birthdayMonth?: Maybe<Scalars['Int']['output']>;
  birthdayYear?: Maybe<Scalars['Int']['output']>;
  contactId: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  deceased: Scalars['Boolean']['output'];
  defaultAccountList?: Maybe<Scalars['String']['output']>;
  developer: Scalars['Boolean']['output'];
  emailAddresses: EmailAddressConnection;
  employer?: Maybe<Scalars['String']['output']>;
  facebookAccounts: FacebookAccountConnection;
  familyRelationships: FamilyRelationshipConnection;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Key Accounts used to authenticate this user */
  keyAccounts: Array<KeyAccount>;
  lastName?: Maybe<Scalars['String']['output']>;
  legalFirstName?: Maybe<Scalars['String']['output']>;
  linkedinAccounts: LinkedinAccountConnection;
  localeDisplay?: Maybe<Scalars['String']['output']>;
  maritalStatus?: Maybe<Scalars['String']['output']>;
  occupation?: Maybe<Scalars['String']['output']>;
  optoutEnewsletter: Scalars['Boolean']['output'];
  phoneNumbers: PhoneNumberConnection;
  preferences?: Maybe<Preference>;
  primaryEmailAddress?: Maybe<EmailAddress>;
  primaryPhoneNumber?: Maybe<PhoneNumber>;
  setup?: Maybe<UserSetupStageEnum>;
  suffix?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  twitterAccounts: TwitterAccountConnection;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  websites: WebsiteConnection;
};

export type UserAdministrativeOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserEmailAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserFacebookAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserFamilyRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserLinkedinAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserPhoneNumbersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserTwitterAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserWebsitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UserDeleteMutation */
export type UserDeleteMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reason: Scalars['String']['input'];
  resettedUserEmail?: InputMaybe<Scalars['String']['input']>;
  resettedUserId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UserDeleteMutation */
export type UserDeleteMutationPayload = {
  __typename?: 'UserDeleteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

/** Autogenerated input type of UserKeySignInMutation */
export type UserKeySignInMutationInput = {
  casTicket: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UserKeySignInMutation */
export type UserKeySignInMutationPayload = {
  __typename?: 'UserKeySignInMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type UserNotification = {
  __typename?: 'UserNotification';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  notification: Notification;
  read: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of UserOptionDeleteMutation */
export type UserOptionDeleteMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UserOptionDeleteMutation */
export type UserOptionDeleteMutationPayload = {
  __typename?: 'UserOptionDeleteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type UserPreferenceUpdateInput = {
  contactsFilter?: InputMaybe<Scalars['String']['input']>;
  hourToSendNotifications?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  localeDisplay?: InputMaybe<Scalars['String']['input']>;
  tabOrders?: InputMaybe<Scalars['String']['input']>;
  tasksFilter?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UserPreferenceUpdateMutation */
export type UserPreferenceUpdateMutationInput = {
  /** attrs to update */
  attributes: UserPreferenceUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UserPreferenceUpdateMutation */
export type UserPreferenceUpdateMutationPayload = {
  __typename?: 'UserPreferenceUpdateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  preference: Preference;
};

/** this is a User but visible fields are significantly limited. */
export type UserScopedToAccountList = {
  __typename?: 'UserScopedToAccountList';
  createdAt: Scalars['ISO8601DateTime']['output'];
  emailAddresses: EmailAddressConnection;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumbers: PhoneNumberConnection;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** this is a User but visible fields are significantly limited. */
export type UserScopedToAccountListEmailAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** this is a User but visible fields are significantly limited. */
export type UserScopedToAccountListPhoneNumbersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for UserScopedToAccountList. */
export type UserScopedToAccountListConnection = {
  __typename?: 'UserScopedToAccountListConnection';
  /** A list of edges. */
  edges: Array<UserScopedToAccountListEdge>;
  /** A list of nodes. */
  nodes: Array<UserScopedToAccountList>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserScopedToAccountListEdge = {
  __typename?: 'UserScopedToAccountListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserScopedToAccountList>;
};

export enum UserSetupStageEnum {
  NoAccountLists = 'NO_ACCOUNT_LISTS',
  NoDefaultAccountList = 'NO_DEFAULT_ACCOUNT_LIST',
  NoOrganizationAccount = 'NO_ORGANIZATION_ACCOUNT',
}

export type UserUpdateInput = {
  almaMater?: InputMaybe<Scalars['String']['input']>;
  anniversaryDay?: InputMaybe<Scalars['Int']['input']>;
  anniversaryMonth?: InputMaybe<Scalars['Int']['input']>;
  anniversaryYear?: InputMaybe<Scalars['Int']['input']>;
  birthdayDay?: InputMaybe<Scalars['Int']['input']>;
  birthdayMonth?: InputMaybe<Scalars['Int']['input']>;
  birthdayYear?: InputMaybe<Scalars['Int']['input']>;
  deceased?: InputMaybe<Scalars['Boolean']['input']>;
  defaultAccountList?: InputMaybe<Scalars['String']['input']>;
  emailAddresses?: InputMaybe<Array<PersonEmailAddressInput>>;
  employer?: InputMaybe<Scalars['String']['input']>;
  facebookAccounts?: InputMaybe<Array<PersonFacebookAccountInput>>;
  familyRelationships?: InputMaybe<Array<PersonFamilyRelationshipInput>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  legalFirstName?: InputMaybe<Scalars['String']['input']>;
  linkedinAccounts?: InputMaybe<Array<PersonLinkedinAccountInput>>;
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  optoutEnewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumbers?: InputMaybe<Array<PersonPhoneNumberInput>>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twitterAccounts?: InputMaybe<Array<PersonTwitterAccountInput>>;
  websites?: InputMaybe<Array<PersonWebsiteInput>>;
};

/** Autogenerated input type of UserUpdateMutation */
export type UserUpdateMutationInput = {
  /** attrs to update */
  attributes?: InputMaybe<UserUpdateInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UserUpdateMutation */
export type UserUpdateMutationPayload = {
  __typename?: 'UserUpdateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  user: User;
};

export type Website = {
  __typename?: 'Website';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

/** The connection type for Website. */
export type WebsiteConnection = {
  __typename?: 'WebsiteConnection';
  /** A list of edges. */
  edges: Array<WebsiteEdge>;
  /** A list of nodes. */
  nodes: Array<Website>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
  /** Total # of pages, based on total count and pagesize */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WebsiteEdge = {
  __typename?: 'WebsiteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Website>;
};

export type WinnersAndLosers = {
  loserId: Scalars['ID']['input'];
  winnerId: Scalars['ID']['input'];
};

/** The sdl representing the federated service capabilities. Includes federation directives, removes federation types, and includes rest of full schema after schema directives have been applied */
export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']['output']>;
};
